import React from 'react'
import Title from '../../components/Title'
import styled from 'styled-components'
import bg from '../../assets/static/bg_skills.jpg'
import capacidad from '../../assets/static/capacidad.png'
import experiencia from '../../assets/static/experiencia.png'
import calidad from '../../assets/static/calidad.png'
import instalaciones from '../../assets/static/instalaciones.png'
import precios from '../../assets/static/precios.png'
import puntualidad from '../../assets/static/puntualidad.png'
import soluciones from '../../assets/static/soluciones.png'
import tecnologia from '../../assets/static/tecnologia.png'
import Button from '../../components/Button'

const Skills = () => {
  return (
    <Main>
      <Container>
        <Title size={'mid'} color="white">
          NUESTRAS VENTAJAS <br /> COMPETITIVAS.
        </Title>
        <Grid>
          <Item>
            <Icon src={experiencia} alt="icon" />
            <TextBox>
              <TitleStyled size={'mid'} color="primary">
                MÁS DE 10 AÑOS DE EXPERIENCIA
              </TitleStyled>
              <P>
                El tiempo nos ha dado la experiencia y nuestra
                capacitación, el respaldo adecuado para ofrecer
                servicios y <b>productos de calidad</b>, acorde a las
                necesidades de nuestros clientes.
              </P>
            </TextBox>
          </Item>
          <Item>
            <Icon src={puntualidad} alt="icon" />
            <TextBox>
              <TitleStyled size={'mid'} color="primary">
                ENTREGAS PUNTUALES
              </TitleStyled>
              <P>
                El compromiso con nuestros clientes es total, contamos
                con un <b>99% de efectividad</b> en entregas a tiempo,
                cumpliendo con los estándares de calidad solicitados.
              </P>
            </TextBox>
          </Item>
          <Item>
            <Icon src={precios} alt="icon" />
            <TextBox>
              <TitleStyled size={'mid'} color="primary">
                PRECIOS COMPETITIVOS
              </TitleStyled>
              <P>
                <b>Somos fabricantes</b>, por lo que podemos ofrecer
                precios competitivos gracias a nuestra eficiencia y a
                nuestras estrategias de <b>reducción de costos</b>.
              </P>
            </TextBox>
          </Item>
          <Item>
            <Icon src={tecnologia} alt="icon" />
            <TextBox>
              <TitleStyled size={'mid'} color="primary">
                TECNOLOGÍA DE PUNTA
              </TitleStyled>
              <P>
                Contamos con equipo de <b>última generación</b>,
                ofreciendo siempre <b>soluciones innovadoras</b> y
                acorde a las exigencias del mercado actual.
              </P>
            </TextBox>
          </Item>
          <Item>
            <Icon src={soluciones} alt="icon" />
            <TextBox>
              <TitleStyled size={'mid'} color="primary">
                SOLUCIONES A LA MEDIDA
              </TitleStyled>
              <P>
                Ofrecemos soluciones en rotomoldeo e inyección,
                buscando <b>satisfacer tus necesidades</b>,
                sustituyendo tus productos de importación.
              </P>
            </TextBox>
          </Item>
          <Item>
            <Icon src={instalaciones} alt="icon" />
            <TextBox>
              <TitleStyled size={'mid'} color="primary">
                INSTALACIONES
              </TitleStyled>
              <P>
                Nuestra planta cuenta con una infraestructura de 5,500
                m2 para{' '}
                <b>
                  cumplir con los procesos de producción requeridos.
                </b>
              </P>
            </TextBox>
          </Item>
          <Item>
            <Icon src={capacidad} alt="icon" />
            <TextBox>
              <TitleStyled size={'mid'} color="primary">
                CAPACIDAD DE PRODUCCIÓN
              </TitleStyled>
              <P>
                Contamos con la{' '}
                <b>capacidad de producción necesaria</b> para cumplir
                con cuentas de gran escala que requieran alto volumen
                de producción asegurada.
              </P>
            </TextBox>
          </Item>
          <Item>
            <Icon src={calidad} alt="icon" />
            <TextBox>
              <TitleStyled size={'mid'} color="primary">
                CONTROLES DE CALIDAD
              </TitleStyled>
              <P>
                Contamos con estrictos controles de calidad y
                <b>certificación ISO-9001-2015</b>, para entregar
                siempre el mejor producto terminado.
              </P>
            </TextBox>
          </Item>
        </Grid>
        <ButtonContainer>
          <Button>MÁS DE NOSOTROS</Button>
        </ButtonContainer>
      </Container>
    </Main>
  )
}

export default Skills

const Main = styled.main`
  background-image: url(${bg});
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  position: relative;
  &::after {
    content: '';
    position: absolute;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 0, 0, 0.8);
    z-index: 1;
  }
`

const Container = styled.div`
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  padding: 75px 0;
  z-index: 2;
  @media (max-width: 768px) {
    align-items: center;
    justify-content: center;
    padding: 50px 15px;
  }
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 50px;
  margin-top: 50px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`
const Item = styled.div`
  display: flex;
  align-items: center;
`

const Icon = styled.img`
  height: 75px;
  margin-right: 25px;
  object-fit: contain;
`

const TextBox = styled.div``

const P = styled.p`
  color: #fff;
  font-family: 'Comfortaa', sans-serif;
  letter-spacing: 0.8px;
  line-height: 1.4;
  text-align: justify;
`

const TitleStyled = styled.div`
  font-weight: 700;
  font-family: 'Ubuntu', sans-serif;
  font-size: ${({ size }) =>
    size === 'big' ? '50px' : size === 'mid' ? '25px' : '18px'};
  margin-bottom: ${({ size }) =>
    size === 'big' ? '50px' : size === 'mid' ? '10px' : '10px'};
  color: ${props => {
    switch (props.color) {
      case 'secondary':
        return props.theme.secondary
      case 'white':
        return props.theme.white
      case 'info':
        return props.theme.info
      case 'success':
        return props.theme.success
      case 'black':
        return props.theme.black

      default:
        return props.theme.primary
    }
  }};

  @media (max-width: 768px) {
    font-size: ${({ size }) =>
      size === 'big' ? '30px' : size === 'mid' ? '25px' : '15px'};
    margin-bottom: ${({ size }) =>
      size === 'big' ? '30px' : size === 'mid' ? '25px' : '15px'};
  }
`

const ButtonContainer = styled.div`
  display: flex;
  justify-content: center;
  margin-top: 50px;
`
