import React from 'react'
import styled from 'styled-components'
import Banner from '../../components/Banner'
import Title from '../../components/Title'
import Button from '../../components/Button'
import Paragraph from '../../components/Paragraph'
import img1 from '../../assets/static/about_img.jpg'
import { Link } from 'react-router-dom'
import img2 from '../../assets/static/about_img2.jpg'
import img3 from '../../assets/static/about_img3.jpg'
import video from '../../assets/static/video2.mp4'
import medal from '../../assets/static/medal.svg'
import shield from '../../assets/static/shield.svg'

const About = () => {
  return (
    <Main>
      <Banner>
        Somos una de las empresas de rotomoldeo y de inyección de
        plástico más grande de México
      </Banner>
      <Container>
        <Article1>
          <TitleContainer>
            <Title size="big">
              Bienvenidos a <Span>FORMEC</Span>
            </Title>
          </TitleContainer>
          <Grid2>
            <Left>
              <Title>
                Contamos con más de 10 años de experiencia en
                rotomoldeo.
              </Title>
              <Paragraph>
                <b>FORMEC</b> fue fundada en diciembre de 1979 y ha
                evolucionado a través de diferentes tipos de negocios
                con el paso del tiempo. En 2011, decidimos participar
                y centrarnos en el proceso de Rotomoldeo, industria de
                constante crecimiento de demanda en México. Hoy,
                <b>FORMEC</b> está entregando macetas a más de 1,200
                tiendas de las principales cadenas minoristas en
                México, como Home Depot, Walmart, Sam’s, Chedraui;
                entre otros.
              </Paragraph>
              <Paragraph>
                Esto hace de <b>FORMEC</b> el productor líder de
                macetas rotativas de polietileno en México, además de
                tener procesos de producción de otro tipo de piezas y
                productos industriales. Nuestros productos ofrecen
                soluciones para industrias como:{' '}
                <b>
                  jardinería, sanitarios portátiles, tarimas,
                  automotriz, agricultura, decoración, entre otros.
                </b>
              </Paragraph>
              <Link to="/solutions">
                <Button>CONOCE NUESTRAS SOLUCIONES</Button>
              </Link>
            </Left>
            <div>
              <Img src={img1} alt="" />
            </div>
          </Grid2>
        </Article1>
        <Article2>
          <TitleContainer>
            <Title color="primary" size="mid">
              NUESTRA POLÍTICA DE CALIDAD
            </Title>
            <Title color="white" size="small">
              En FORMEC, hemos decidido producir artículos de calidad
              respaldados por nuestra capacitación y honestidad
              personal, asegurando el cumplimiento de los
              requerimientos de nuestros clientes, mediante la
              revisión y mejora continua de los procesos de nuestro
              sistema de calidad.
            </Title>
          </TitleContainer>
        </Article2>
        <Artiecle3 />
        <Article1>
          <TitleContainer>
            <Title size="small">
              CONOCE NUESTROS PROCESOS Y SOLUCIONES
            </Title>
          </TitleContainer>
          <Grid2>
            <div>
              <Video autoPlay muted controls={true} loop>
                <source src={video} type="video/mp4" />
              </Video>
            </div>
            <Left>
              <Title size="small">
                CONTAMOS CON CERTIFICACIÓN DE CALIDAD ISO 9001:2015
              </Title>
              <ImgPContainer>
                <Iso src={img3} alt="" />
                <Paragraph>
                  La norma ISO 9001:2015 es el estándar internacional
                  de carácter certificable que regula los sistemas de
                  gestión de la calidad. La serie de normas ISO 9000
                  promueven la adopción de un enfoque basado en
                  procesos​.
                </Paragraph>
              </ImgPContainer>
            </Left>
          </Grid2>
        </Article1>
        <Article4>
          <div>
            <Left>
              <Title size="small">
                OBJETIVO DE NUESTRA POLÍTICA DE CALIDAD
              </Title>
              <ImgPContainer>
                <Svg src={medal} alt="" />
                <div>
                  <Paragraph>
                    1. Lograr que el personal de <b>FORMEC</b> se
                    convenza de que la capacitación y el crecimiento
                    personal deben ser un hábito.
                  </Paragraph>
                  <Paragraph>
                    2. Mantener y mejorar la eficiencia y
                    productividad de <b>FORMEC</b>, así como la
                    calidad de las partes plásticas producidas y de
                    los servicios entregados en base a una tabla de
                    objetivos que se revisará periódicamente a manera
                    de satisfacer las necesidades nuestras y de
                    nuestros clientes.
                  </Paragraph>
                </div>
              </ImgPContainer>
            </Left>{' '}
            <Left>
              <Title size="small">NUESTRA MISIÓN</Title>
              <ImgPContainer>
                <Svg src={shield} alt="" />
                <Paragraph>
                  <b>FORMEC</b>, tiene como misión generar un valor
                  para nuestros clientes, proveedores, empleados y
                  accionistas a través de la excelencia en el servicio
                  y la mejora continua, exceder sus expectativas y
                  llegar a ser su mejor opción.
                </Paragraph>
              </ImgPContainer>
            </Left>
          </div>
        </Article4>
      </Container>
    </Main>
  )
}

export default About

const Main = styled.main``
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  background-color: ${({ theme }) => theme.white};

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Span = styled.span`
  color: #000;
`

const Article1 = styled.article`
  grid-column: 2/3;
  margin-bottom: 30px;

  @media (max-width: 768px) {
    grid-column: 1/2;
    padding: 0 20px;
  }
`

const TitleContainer = styled.div`
  text-align: center;
  margin: 75px 0;
`

const Grid2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const Article2 = styled.article`
  grid-column: 1/4;
  background-color: ${({ theme }) => theme.bgWeb};
`

const Artiecle3 = styled.article`
  grid-column: 1/4;
  background-image: url(${img2});
  height: 50vh;
  background-position: center;
  background-repeat: no-repeat;
  background-size: cover;
`

const ImgPContainer = styled.div`
  display: flex;
  align-items: flex-start;
`

const Iso = styled.img`
  width: 100px;
  margin-right: 20px;
  object-fit: contain;
  margin-top: 25px;
`

const Svg = styled.img`
  height: 75px;
  margin-right: 20px;
  object-fit: contain;
  margin-top: 25px;
`

const Video = styled.video`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Article4 = styled.article`
  grid-column: 1/4;
  margin-bottom: 30px;
  background-color: ${({ theme }) => theme.grayLight};
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  padding: 50px 0;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    box-sizing: border-box;
    padding: 0 20px;
  }
  & > div {
    grid-column: 2/3;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    @media (max-width: 768px) {
      grid-template-columns: 1fr;
      box-sizing: border-box;
      padding: 0 20px;
    }
  }
`
