import React from 'react'
import styled from 'styled-components'

const Paragraph = ({ children, color }) => {
  return <P color={color}>{children}</P>
}

export default Paragraph

const P = styled.p`
  font-size: 1rem;
  text-align: justify;
  margin-top: 25px;
  font-weight: 300;

  color: ${({ color }) => {
    switch (color) {
      case 'white':
        return '#fff'

      default:
        return '#000'
    }
  }};
`
