import { combineReducers } from 'redux'
import reducerApp from './reducer'
import products from '../pages/Products/reducer'

const reducer = combineReducers({
  reducerApp,
  products,
})

export default reducer
