import React from 'react'
import styled from 'styled-components'
import Title from '../../components/Title'
import bg from '../../assets/static/bg-banner.jpg'

const Banner = ({ children }) => {
  return (
    <Main>
      <Container>
        <Title size={'mid'} color={'white'}>
          {children}
        </Title>
      </Container>
    </Main>
  )
}

export default Banner

const Main = styled.main`
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  background-image: url(${bg});
  background-position: center;
  position: relative;
  color: #fff;
  height: 40vh;
  border-bottom: 2px solid ${({ theme }) => theme.primary};
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 72, 124, 0.8);
    z-index: 1;
  }
`

const Container = styled.div`
  grid-column: 2/3;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
  z-index: 1;
  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    align-items: center;
    margin-top: 70px;
    padding: 0 20px;
    box-sizing: border-box;
  }
`
