import React from 'react'
import styles from 'styled-components'
import Select from 'react-select'

const Component = props => {
  const { options } = props

  return (
    <StyledSelect
      {...props}
      classNamePrefix="Select"
      options={options}
    />
  )
}

export default Component

const StyledSelect = styles(Select)`
.Select-container{
  z-index: 100;
    background-color: ${props => props.theme.backgroundButton};
    color: ${props => props.theme.text};
}
.Select__value-container{
    background-color: ${props => props.theme.backgroundButton};
    color: ${props => props.theme.text};
}
.Select__indicators{
    color: ${props => props.theme.text};
    background-color: ${props => props.theme.backgroundButton};
}

.Select__placeholder{
    color: ${props => props.theme.colorButtonCircle};
}
.Select__input-container{
    color: ${props => props.theme.text};
}
.Select__menu{
  z-index: 200 !important;
    color: ${props => props.theme.text};
    background-color: ${props => props.theme.backgroundButton};
}
.Select__menu-list{
    color: ${props => props.theme.text};
    background-color: ${props => props.theme.backgroundButton};
}
.Select__option{
    background-color: ${props => props.theme.backgroundButton};
    color: ${props => props.theme.text};
    :hover{
        background: ${({ theme }) =>
          `linear-gradient(to bottom right, ${theme.primary} 0%, ${theme.primary_scale1} 100%) !important`};
    }
}
.Select__control {
    color: ${props => props.theme.text};
    background-color: ${props => props.theme.backgroundButton};
  }


`
