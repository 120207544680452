import React from 'react'
import styled from 'styled-components'
import Banner from '../../components/Banner'
import Title from '../../components/Title'
import Button from '../../components/Button'
import Hr from '../../components/Hr'
import Paragraph from '../../components/Paragraph'
import img1 from '../../assets/static/solutions_img1.png'
import img2 from '../../assets/static/solutions_img2.jpg'
import img3 from '../../assets/static/solutions_img3.jpg'
import img4 from '../../assets/static/solutions_img4.png'
import img5 from '../../assets/static/solutions_img5.jpeg'
import img6 from '../../assets/static/solutions_img6.png'
import { Link } from 'react-router-dom'
const About = () => {
  return (
    <Main>
      <Banner>
        <>
          NUESTRAS SOLUCIONES
          <Hr color="primary" />
          <Title size="small" color="white">
            Adaptarnos a sus particularidades nos ha dado la
            experiencia para ofrecer un servicio integral con una muy
            elevada fiabilidad.
          </Title>
        </>
      </Banner>
      <Article1>
        <div>
          <Img src={img1} alt="" />
        </div>
        <Content>
          <Title size="mid" color="white">
            Desarrollo de proyectos
          </Title>
          <Paragraph color="white">
            Ofrecemos soluciones desde la conceptualización misma del
            proyecto y vamos de la mano con nuestros clientes en todo
            el proceso de concepción de este, para asegurar que el
            resultado final cumple con las expectativas que se
            generaron de lo que comenzó por ser una idea.
          </Paragraph>
          <Paragraph />
          <Link to="/contact">
            <Button color="transparent">CONOCER MÁS</Button>
          </Link>
        </Content>
      </Article1>
      <Article1>
        <Content>
          <Title size="mid" color="white">
            Roto moldeo de plásticos
          </Title>
          <Paragraph color="white">
            Ofrecemos soluciones de roto moldeo de aplicación en una
            gran variedad de industrias. Este método de moldeo
            versátil es especialmente adecuado para productos huecos
            grandes.
          </Paragraph>
          <Paragraph />
          <Link to="/contact">
            <Button color="transparent">CONOCER MÁS</Button>
          </Link>
        </Content>
        <div>
          <Img src={img2} alt="" />
        </div>
      </Article1>
      <Article1>
        <div>
          <Img src={img3} alt="" />
        </div>
        <Content>
          <Title size="mid" color="white">
            Inyección de plásticos
          </Title>
          <Paragraph color="white">
            Para la fabricación de producto de plástico por inyección
            creamos un proceso de manufactura con su diseño e
            ingeniería y con materiales que cumplan con sus
            especificaciones para ofrecer a nuestros clientes
            productos de alta calidad a un costo competitivo.
          </Paragraph>
          <Paragraph />
          <Link to="/contact">
            <Button color="transparent">CONOCER MÁS</Button>
          </Link>
        </Content>
      </Article1>
      <Article1>
        <Content>
          <Title size="mid" color="white">
            Inyección de Poliuretano (PU)
          </Title>
          <Paragraph color="white">
            La espuma de poliuretano se aplica de manera líquida in
            situ, es decir, que los componentes se mezclan
            directamente en obra y el resultado se inyecta a través de
            una serie de cavidades en la pieza que se desee aislar y/o
            reforzar. Una vez inyectada, la espuma se expande dando
            lugar a un aislante rígido, duradero y resistente.
          </Paragraph>
          <Paragraph />
          <Link to="/contact">
            <Button color="transparent">CONOCER MÁS</Button>
          </Link>
        </Content>
        <div>
          <Img src={img4} alt="" />
        </div>
      </Article1>
      <Article1>
        <div>
          <Img src={img5} alt="" />
        </div>
        <Content>
          <Title size="mid" color="white">
            Logística y transportación
          </Title>
          <Paragraph color="white">
            Contamos con un área de logística con la cual podemos
            ofrecer el servicio de empaquetado y distribución de los
            productos terminados acorde a las especificaciones y
            necesidades de nuestros clientes.
          </Paragraph>
          <Paragraph />
          <Link to="/contact">
            <Button color="transparent">CONOCER MÁS</Button>
          </Link>
        </Content>
      </Article1>
      <Article3>
        <Content2>
          <Float src={img6} alt="" />
          <Box>
            <Title size="small" color="white">
              Contamos con una amplia gama de productos con diferentes
              aplicaciones y soluciones
            </Title>
            <Link to="/products">
              <Button>VER PRODUCTOS</Button>
            </Link>
          </Box>
        </Content2>
        <Content2>
          <Box>
            <Title size="mid" color="secondary">
              ¿Tienes una idea que te gustaría desarrollar?
            </Title>
            <Title size="small" color="black">
              Juntos hagámosla realidad
            </Title>
            <Link to="/contact">
              <Button>PONERSE EN CONTACTO</Button>
            </Link>
          </Box>
        </Content2>
      </Article3>
    </Main>
  )
}

export default About

const Main = styled.main`
  background-color: #fff;
`

const Article1 = styled.article`
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Content = styled.div`
  background-color: #47657e;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 75px;
`

const Content2 = styled.div`
  background-color: #FAFAFA;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Article3 = styled.article`
  grid-column: 1/4;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column;
  }
  & > div {
    width: 100%;
    position: relative;
    @media (max-width: 768px) {
      align-items: center;
      box-sizing: border-box;
      padding: 20px;
    }
  }
  & > div:nth-child(1) {
    background-color: ${({ theme }) => theme.bgWeb};
    display: flex;
    align-items: flex-end;
    padding: 80px 0;
    @media (max-width: 768px) {
      align-items: center;
      box-sizing: border-box;
      padding: 20px;
    }
  }
`

const Box = styled.div`
  width: 450px;
  margin: 0 25px;
  text-align: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const Float = styled.img`
  position: absolute;
  top: 0px;
  left: 0;
  height: 300px;
  @media (max-width: 768px) {
    display: none;
  }
`
