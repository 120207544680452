import React, { useState } from 'react'
import styles from 'styled-components'
import { connect } from 'react-redux'
import Profile from '../../components/Profile'
import {
  BsMoon,
  BsSun,
  BsFullscreenExit,
  BsThreeDotsVertical,
} from 'react-icons/bs'
import { BiMenuAltLeft } from 'react-icons/bi'
import ButtonCircle from '../../components/ButtonCircle'
import logo from '../../assets/static/logo.svg'
import logo_white from '../../assets/static/logo_white.svg'

const Footer = props => {
  const {
    onTheme,
    theme,
    toggleBarButton,
    toggleBar,
    toggleBarMouse,
  } = props
  const fullScreen = () => {
    if (document.fullscreenElement) {
      document.exitFullscreen()
    } else {
      document.documentElement.requestFullscreen()
    }
  }
  const [isOpen, setIsOpen] = useState(false)
  const finalLogo = !theme ? logo : logo_white

  return (
    <Topbar toggleBar={toggleBar} toggleBarMouse={toggleBarMouse}>
      <Menu>
        <ButtonCircle onClick={toggleBarButton}>
          <BiMenuAltLeft />
        </ButtonCircle>
        <MobileTopBar>
          <Logo src={finalLogo} alt="logo" />
        </MobileTopBar>
        <MobileTopBar>
          <ButtonCircle onClick={() => setIsOpen(!isOpen)}>
            <BsThreeDotsVertical />
          </ButtonCircle>
        </MobileTopBar>
      </Menu>
      <ButtonBar isOpen={isOpen}>
        <ButtonCircle onClick={onTheme}>
          {theme ? <BsSun /> : <BsMoon />}
        </ButtonCircle>
        <ButtonCircle onClick={() => fullScreen()}>
          <BsFullscreenExit />
        </ButtonCircle>
        <Profile />
      </ButtonBar>
    </Topbar>
  )
}

export default connect(null, null)(Footer)

const Topbar = styles.div`
grid-area: topbar;
position: fixed;
width: calc(100% - ${props =>
  props.toggleBar && !props.toggleBarMouse ? '80px' : '270px'});
margin-left: ${props =>
  props.toggleBar && !props.toggleBarMouse ? '80px' : '270px'};
height: 75px;
display: flex;
justify-content: space-between;
align-items: center;
background-color: ${({ theme }) => theme.backgroundButton};
box-size: border-box;
padding-left: 20px;
border: ${({ theme }) => theme.border};
border-collapse: collapse;
z-index: 1;
@media (max-width: 768px) {
  width: 100%;
  margin-left: 0;
}
`

const Menu = styles.div`
display: flex;
align-items: center;
justify-content: flex-start;
@media (max-width: 768px) {
  justify-content: space-between;
  width: 100vw;
  margin-right: 40px;
  position: relative;
 }
`

const ButtonBar = styles.div`
display: flex;
justify-content: flex-end;
align-items: center;
padding-right: 40px;
@media (max-width: 768px) {
  transition: all 0.2s linear 0ms;
  position: absolute;
  background-color: ${({ theme }) => theme.backgroundButton};
  bottom: -100%;
  left: 0;
  width: 100vw;
  justify-content: space-around;
  box-size: border-box;
  height: 75px;
  border: ${({ theme }) => theme.border};
  transform-origin: 0 0 0;
  ${props =>
    props.isOpen
      ? `
   transform: scaleY(1);
    
    `
      : 'transform: scaleY(0)'}
 }
`
const MobileTopBar = styles.div`
display: none;
@media (max-width: 768px) {
 display: block;
}
`
const Logo = styles.img`
  height: 36px;
`
