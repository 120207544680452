import React from 'react'
import styled from 'styled-components'

const Footer = () => {
  return (
    <Main>
      <Container>
        <P>Política de privacidad</P>
        <P>© 2023 Grupo FORMEC, Derechos Reservados</P>
        <P>
          <Link href="/dashboard">Portal Cliente</Link>
        </P>
        <P>Powered by: Mighty Code</P>
      </Container>
    </Main>
  )
}

export default Footer

const Main = styled.footer`
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1000px) 1fr;
  color: #787878;
  position: relative;
  background-color: #1d1d1d;
  padding: 10px 0;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Container = styled.div`
  grid-column: 2/3;
  display: flex;
  justify-content: space-between;
  @media (max-width: 768px) {
    flex-direction: column;
    grid-column: 1;
    padding: 0 15px;
  }
`

const P = styled.p`
  grid-column: 2/3;
  display: flex;
  font-family: 'PT Sans', sans-serif;
  align-items: center;
  justify-content: center;
  text-align: center;
  @media (max-width: 768px) {
    flex-direction: column;
    grid-column: 1;
    padding: 0 15px;
  }
`

const Link = styled.a`
  text-decoration: none;
  color: #787878;
`
