import React from 'react'
import styled from 'styled-components'
import Banner from '../../components/Banner'
import Title from '../../components/Title'
import Paragraph from '../../components/Paragraph'
import img1 from '../../assets/static/contact_img1.jpg'
import mail from '../../assets/static/mail.svg'
import phone from '../../assets/static/phone.svg'
import hosue from '../../assets/static/house.svg'

import Contact from '../Home/Contact'
const About = () => {
  return (
    <Main>
      <Banner>
        <>
          Empecemos a trabajar juntos
          <Title size="mid" color="primary">
            Platícanos de tu proyecto
          </Title>
        </>
      </Banner>
      <Container>
        <Article>
          <div>
            <Img src={img1} alt="" />
          </div>
          <Content>
            <Item>
              <Icon src={mail} alt="icon" />
              <div>
                <Title size="mid" color="black">
                  E-mail
                </Title>
                <Paragraph>info@grupoformec.com</Paragraph>
              </div>
            </Item>
            <Item>
              <Icon src={phone} alt="icon" />
              <div>
                <Title size="mid" color="black">
                  Teléfono
                </Title>
                <Paragraph>+52 818 336 2016</Paragraph>
              </div>
            </Item>
          </Content>
        </Article>
        <Article2>
          {/* <Mapa
            src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d7520.659811590819!2d-99.204854!3d19.527445!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x85d21d1ded0784dd%3A0x126463d95e1e3f3e!2sIxtlahuaca%2010%2C%20Tlalnemex%2C%2054070%20Tlalnepantla%20de%20Baz%2C%20M%C3%A9x.!5e0!3m2!1ses!2smx!4v1681251968108!5m2!1ses!2smx"
            allowfullscreen=""
            loading="lazy"
            referrerpolicy="no-referrer-when-downgrade"
          ></Mapa> */}
          <MapaContainer>
            <Mapa
              src="https://www.google.com/maps/embed?pb=!1m14!1m8!1m3!1d28765.320356218832!2d-100.4534!3d25.682396!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x8662bfc220fee575%3A0x458bc48c50bdcb1c!2sFormec%20S.A.%20de%20C.V.!5e0!3m2!1ses!2smx!4v1681251842738!5m2!1ses!2smx"
              allowfullscreen=""
              loading="lazy"
              referrerolicy="no-referrer-when-downgrade"
              title="mapa"
            ></Mapa>
          </MapaContainer>
          <Item>
            <Icon src={hosue} alt="icon" />
            <div>
              <Title size="mid" color="black">
                Planta
              </Title>
              <Paragraph>
                Carr. Monterrey-Saltillo 3061 Fracc. Bosques del
                Poniente 66362 Santa Catarina, N.L., México
              </Paragraph>
            </div>
          </Item>
        </Article2>
        <Article2>
          {/*  <Item>
            <Icon src={hosue} alt="icon" />
            <div>
              <Title size="mid" color="black">
                Planta
              </Title>
              <Paragraph>
                Carr. Monterrey-Saltillo 3061 Fracc. Bosques del
                Poniente 66362 Santa Catarina, N.L., México
              </Paragraph>
            </div>
          </Item> */}
          <Item>
            {/*  <Icon src={hosue} alt="icon" />
            <div>
              <Title size="mid" color="black">
                CEDIS México
              </Title>
              <Paragraph>
                +Ixtlahuaca 10 esq. Acolman Col. Tlalnemex La Loma
                54070 Tlalnepantla de Baz, Edo Mex. +52 55 5384 0197
              </Paragraph>
            </div> */}
          </Item>
        </Article2>
      </Container>
      <Contact />
    </Main>
  )
}

export default About

const Main = styled.main`
  background-color: #fff;
`

const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Article = styled.article`
  grid-column: 2/3;
  display: flex;
  margin: 50px 0;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const Img = styled.img`
  height: 300px;
  margin-right: 75px;
  @media (max-width: 768px) {
    margin-right: 0;
    height: auto;
    width: 100%;
  }
`

const Content = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  height: 100%;

  @media (max-width: 768px) {
    margin-top: 25px;
    padding: 0 20px;
  }
`

const Item = styled.div`
  display: flex;
  align-items: center;
`

const Icon = styled.img`
  width: 70px;
  margin-right: 25px;
`

const Article2 = styled.article`
  grid-column: 2/3;
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 50px;
  margin-top: 50px;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    padding: 0 20px;
  }
`

const Mapa = styled.iframe`
  width: 100%;
  height: 300px;
  border: 0;
`

const MapaContainer = styled.div`
  margin-right: 40px;
`
