import React from 'react'
import styled from 'styled-components'
import bg from '../../assets/static/bg_products.jpg'
import maceta from '../../assets/static/maceta_products.jpg'
import Button from '../../components/Button'
import Title from '../../components/Title'

const Products = () => {
  return (
    <Main>
      <Img src={bg} alt="bg" />
      <Item>
        <Title size={'mid'} color="white">
          Nuestra capacidad instalada.
        </Title>
        <P>
          Todos nuestros equipos reciben mantenimiento y revisiones
          preventivas constantes, esto para asegurar su óptimo
          funcionamiento para ofrecer el mejor desempeño siempre.
        </P>
        <div>
          <Button>VER MÄS</Button>
        </div>
      </Item>
      <Item>
        <Title size={'mid'} color="white">
          Nuestros productos.
        </Title>
        <P>
          En <b>FORMEC</b>, hemos decidido producir artículos de
          calidad respaldados por nuestra capacitación y honestidad
          personal, asegurando el cumplimiento de los requerimientos
          de nuestros clientes, mediante la revisión y mejora continua
          de los procesos de nuestro sistema de calidad.
        </P>
        <div>
          <Button>VER PRODUCTOS</Button>
        </div>
      </Item>
      <Img src={maceta} alt="maceta" />
    </Main>
  )
}

export default Products

const Main = styled.main`
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr 1fr;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Img = styled.img`
  width: 100%;
  object-fit: cover;
  height: 450px;
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  background-color: #365b75;
  padding: 50px 100px;
  @media (max-width: 768px) {
    padding: 50px 20px;
  }
`

const P = styled.p`
  color: white;
  text-align: justify;
  font-family: 'Comfortaa', sans-serif;
  margin-bottom: 50px;
`
