import React, { useState } from 'react'
import { connect } from 'react-redux'
import { create } from '../../actions'
import styled from 'styled-components'
import Title from '../../components/Title'
import Input from '../../components/InputWeb'
import Button from '../../components/Button'
import Swal from 'sweetalert2'

const Contact = props => {
  const [form, setForm] = useState({})

  const handleForm = e => {
    setForm({
      ...form,
      [e.target.name]: e.target.value,
    })
  }

  const handleSubmit = e => {
    e.preventDefault()
    props
      .create('contact', 'POST_BANNER', form)
      .then(() => {
        Swal.fire({
          icon: 'success',
          title: 'Mensaje enviado',
          text: 'Pronto nos pondremos en contacto contigo',
          showConfirmButton: false,
          timer: 1500,
        })
      })
      .then(() => {
        document.getElementById('contactForm').reset()
      })
  }

  return (
    <Main id="contact">
      <Container>
        <Title size={'mid'} color="secondary">
          Será un gusto para nosotros ofrecerte una solución.
        </Title>
        <P>
          <b>CONTÁCTANOS</b>
          <br /> Juntos podremos encontrar la mejor opción para tus
          requerimientos en rotomoldeo e inyección de plásticos.
          Déjanos tus datos y nos pondremos en contacto a la brevedad.
        </P>
        <Form id="contactForm" onSubmit={e => handleSubmit(e)}>
          <Input
            placeholder="Nombre"
            name="name"
            type="text"
            onChange={e => handleForm(e)}
          />
          <Input
            placeholder="Correo"
            name="email"
            type="email"
            onChange={e => handleForm(e)}
          />
          <Input
            placeholder="Teléfono"
            name="phone"
            type="text"
            onChange={e => handleForm(e)}
          />

          <Textarea
            placeholder="Escriba aquí su mensaje y uno de nuestros asesores se pondrá en contacto con usted."
            name="mesaje"
            onChange={e => handleForm(e)}
          />

          <Button color="white" type="submit">
            ENVIAR MENSAJE
          </Button>
        </Form>
      </Container>
    </Main>
  )
}

const mapDispatchToProps = {
  create,
}

export default connect(null, mapDispatchToProps)(Contact)

const Main = styled.main`
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  background-color: ${({ theme }) => theme.white};
  position: relative;
`

const Container = styled.div`
  grid-column: 2/3;
  display: grid;
  grid-template-columns: 550px 1fr;
  padding: 75px 0;
  gap: 10px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 50px 15px;
  }
`

const P = styled.p`
  font-size: 'Comfortaa', sans-serif;
  font-size: 1.2rem;
  font-weight: 100;
  margin: 0;
`

const Form = styled.form`
  grid-column: 1/4;
  padding: 50px 0;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
  grid-gap: 20px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
  }
`

const Textarea = styled.textarea`
  grid-column: 1/4;
  resize: none;
  width: 100%;
  height: 100%;
  padding: 0.375rem 0.75rem;
  font-size: 0.875rem;
  line-height: 1.5;
  outline-color: ${({ theme }) => theme.secondary};
  color: ${({ theme }) => theme.secondary};
  background-color: ${({ theme }) => theme.white};
  background-clip: padding-box;
  border: ${({ theme }) => theme.border};
  border-color: ${({ theme }) => theme.primary};
  border-radius: 10px;
  box-shadow: none;
  margin-bottom: 0.375rem;
  font-family: 'PT Sans', sans-serif;
  transition: border-color 0.15s ease-in-out,
    box-shadow 0.15s ease-in-out;
  box-sizing: border-box;

  @media (max-width: 768px) {
    height: 200px;
  }
`
