import React from 'react'
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Layout from '../layout'
import Login from '../pages/Login'
import Home from '../pages/Home'
import About from '../pages/About'
import Capacity from '../pages/Capacity'
import Solutions from '../pages/Solutions'
import ProductsWeb from '../pages/Products/Web'
import Contact from '../pages/Contact'

import Dashboard from '../pages/Dashboard'
import Products from '../pages/Products'
import Oc from '../pages/Oc'
import OcCreate from '../pages/Oc/Create'

const App = ({ loggedIn }) => {
  if (loggedIn) {
    return (
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/capacity" element={<Capacity />} />
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/products" element={<ProductsWeb />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="/login" element={<Login />} />
            <Route path="/dashboard" element={<Dashboard />} />
            <Route
              path="/dashboard/products"
              element={<Products />}
            />
            <Route path="/dashboard/oc" element={<Oc />} />
            <Route
              path="/dashboard/oc/create"
              element={<OcCreate />}
            />
          </Routes>
        </Layout>
      </BrowserRouter>
    )
  } else {
    return (
      <BrowserRouter>
        <Layout>
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/about" element={<About />} />
            <Route path="/capacity" element={<Capacity />} />
            <Route path="/solutions" element={<Solutions />} />
            <Route path="/products" element={<ProductsWeb />} />
            <Route path="/contact" element={<Contact />} />
            <Route path="*" element={<Login />} />
          </Routes>
        </Layout>
      </BrowserRouter>
    )
  }
}

export default App
