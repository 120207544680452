import React from 'react'
import styled from 'styled-components'
import Title from '../../components/Title'
import Button from '../../components/Button'
import { HashLink } from 'react-router-hash-link'
import video from '../../assets/static/video.mp4'

const Banner = () => {
  return (
    <Main>
      <VideoContainer>
        <Video autoPlay muted controls={false} loop>
          <source src={video} type="video/mp4" />
        </Video>
      </VideoContainer>
      <Container>
        <Title size={'small'} color={'white'}>
          EMPRESA DEDICADA A LA FABRICACIÓN DE
        </Title>
        <Title size={'big'} color={'white'}>
          PIEZAS DE PLÁSTICO POR ROTOMOLDEO E INYECCIÓN
        </Title>
        <HashLink to="/#contact" smooth>
          <Button color={'secondary'}>CONTACTAR A UN ASESOR</Button>
        </HashLink>
      </Container>
    </Main>
  )
}

export default Banner

const Main = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  background-color: ${({ theme }) => theme.white};
  position: relative;
  color: #fff;
`

const Container = styled.div`
  grid-column: 2/3;
  height: 100%;
  width: 70%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  z-index: 1;
  @media (max-width: 768px) {
    width: 100%;
    text-align: center;
    align-items: center;
  }
`

const VideoContainer = styled.div`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;

  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 72, 124, 0.8);
    z-index: 1;
  }
`

const Video = styled.video`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`
