import React from 'react'
import styled from 'styled-components'
import Title from '../../components/Title'
import homedepot from '../../assets/static/homedepot.png'
import walmart from '../../assets/static/walmart.png'
import soriana from '../../assets/static/soriana.png'
import chedraui from '../../assets/static/chedraui.png'
import marcopolo from '../../assets/static/marcopolo.png'
import mercedesbenz from '../../assets/static/mercedesbenz.png'
import xylem from '../../assets/static/xylem.png'
import bissett from '../../assets/static/bissett.png'
import houston from '../../assets/static/houston.png'
import campania from '../../assets/static/campania.png'
import dees from '../../assets/static/dees.png'
import barn from '../../assets/static/barn.png'

const Solutions = () => {
  return (
    <Main>
      <Container>
        <Title size={'mid'} color="secondary">
          Damos servicio a clientes comerciales e industriales.
        </Title>
        <P>
          Hemos afianzado relaciones duraderas con{' '}
          <b>
            clientes nacionales e internacionales en el comercio y la
            industria
          </b>
          . Lo más importante para nosotros es la calidad y
          satisfacción,
          <b>generando confianza</b> gracias a las soluciones que
          ofrecemos a cada uno de nuestros proyectos. Algunos de
          nuestros clientes son:
        </P>
        <Grid>
          <Item>
            <Img src={homedepot} alt="homedepot" />
          </Item>
          <Item>
            <Img src={walmart} alt="walmart" />
          </Item>
          <Item>
            <Img src={soriana} alt="soriana" />
          </Item>
          <Item>
            <Img src={chedraui} alt="chedraui" />
          </Item>
          <Item>
            <Img src={marcopolo} alt="marcopolo" />
          </Item>
          <Item>
            <Img src={mercedesbenz} alt="mercedesbenz" />
          </Item>
          <Item>
            <Img src={xylem} alt="xylem" />
          </Item>
          <Item>
            <Img src={bissett} alt="bissett" />
          </Item>
          <Item>
            <Img src={houston} alt="houston" />
          </Item>
          <Item>
            <Img src={campania} alt="campania" />
          </Item>
          <Item>
            <Img src={dees} alt="dees" />
          </Item>
          <Item>
            <Img src={barn} alt="barn" />
          </Item>
        </Grid>
      </Container>
    </Main>
  )
}

export default Solutions

const Main = styled.main`
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  background-color: ${({ theme }) => theme.white};
  position: relative;
`

const Container = styled.div`
  grid-column: 2/3;
  display: grid;
  grid-template-columns: 550px 1fr;
  padding: 75px 0;
  gap: 10px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 50px 15px;
  }
`

const P = styled.p`
  font-size: 'Comfortaa', sans-serif;
  font-size: 1.2rem;
`

const Grid = styled.div`
  grid-column: 1/3;
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  max-width: 100%;
  margin: 50px 0;
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Img = styled.img`
  width: 150px;
  height: 150px;
`
