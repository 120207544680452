export default function reducerCustomers(state = {}, action) {
  switch (action.type) {
    case 'GET_PRODUCTS':
      return {
        ...state,
        products: action.payload.data,
      }
    case 'GET_COLORS':
      return {
        ...state,
        colors: action.payload.data,
      }
    case 'GET_MODELS':
      return {
        ...state,
        models: action.payload.data,
      }
    case 'GET_CATEGORIES':
      return {
        ...state,
        categories: action.payload.data,
      }
    case 'GET_FAMILIES':
      return {
        ...state,
        families: action.payload.data,
      }
    case 'GET_BARCODES':
      return {
        ...state,
        barcodes: action.payload.data,
      }
    default:
      return state
  }
}
