import React, { useState, useEffect } from 'react'
import styled from 'styled-components'
import { Link, useLocation } from 'react-router-dom'
import { AiOutlineMenu, AiOutlineClose } from 'react-icons/ai'
import logo from '../../assets/static/logo.png'
import bg from '../../assets/static/bg.png'
import { FiChevronDown } from 'react-icons/fi'
import { HashLink } from 'react-router-hash-link'

const Header = () => {
  const [togleBar, setTogle] = useState(false)
  const location = useLocation()
  const [togleSubmenu, setTogleSubmenu] = useState(false)
  const menu = [
    {
      id: 1,
      name: 'Inicio',
      link: '/',
      active: true,
    },
    {
      id: 2,
      name: 'Nosotros',
      link: '/about',
      active: false,
    },
    {
      id: 3,
      name: 'Capacidad instalada',
      link: '/capacity',
      active: false,
    },
    {
      id: 5,
      name: 'Soluciones',
      link: '/solutions',
      active: false,
    },
    {
      id: 4,
      name: 'Productos',
      link: '/products',
      active: false,
      isOpen: false,
      submenu: [
        {
          id: 1,
          name: 'Macetas',
          link: '/products#macetas',
        },
        {
          id: 2,
          name: 'Baños Portátiles',
          link: '/products#banos',
        },
        {
          id: 3,
          name: 'Señalamientos',
          link: '/products#sena',
        },
        {
          id: 4,
          name: 'Tarimas',
          link: '/products#tarimas',
        },
        {
          id: 5,
          name: 'Contenedores',
          link: '/products#contenedores',
        },
      ],
    },

    {
      id: 6,
      name: 'Contacto',
      link: '/contact',
      active: false,
    },
  ]

  const [scrollY, setScrollY] = useState(0)

  useEffect(() => {
    const handleScroll = () => {
      setScrollY(window.scrollY)
    }
    handleScroll()

    window.addEventListener('scroll', handleScroll)
    return () => {
      window.removeEventListener('scroll', handleScroll)
    }
  }, [])

  return (
    <HeaderS scroll={scrollY}>
      <Menu togleBar={togleBar}>
        <Img src={logo} alt="logo" scroll={scrollY} />
        <Ul togleBar={togleBar}>
          {menu.map((menu, index) =>
            menu.submenu ? (
              <Li
                key={index}
                onMouseEnter={() => {
                  setTogleSubmenu(true)
                }}
                onMouseLeave={() => {
                  setTogleSubmenu(false)
                }}
                onClick={() => {
                  setTogleSubmenu(!togleSubmenu)
                }}
              >
                <Span
                  scroll={scrollY}
                  className={
                    location.pathname.includes(menu.link)
                      ? 'active'
                      : ''
                  }
                >
                  {menu.name} <FiChevronDown />
                </Span>
                <SubMenu togleSubmenu={togleSubmenu}>
                  {menu.submenu.map((submenu, i) => (
                    <li key={i}>
                      <SubLink
                        to={submenu.link}
                        smooth
                        onClick={() => setTogle(false)}
                      >
                        {submenu.name}
                      </SubLink>
                    </li>
                  ))}
                </SubMenu>
              </Li>
            ) : (
              <Li key={index} scroll={scrollY}>
                <Link
                  to={menu.link}
                  onClick={() => setTogle(false)}
                  className={
                    location.pathname === menu.link ? 'active' : ''
                  }
                >
                  {menu.name}
                </Link>
              </Li>
            )
          )}
        </Ul>

        <Hamburguer onClick={() => setTogle(!togleBar)}>
          {togleBar ? <AiOutlineClose /> : <AiOutlineMenu />}
        </Hamburguer>
      </Menu>
    </HeaderS>
  )
}

export default Header

const HeaderS = styled.header`
  position: fixed;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  height: ${({ scroll }) => (scroll > 100 ? '60px' : '80px')};
  width: 100vw;
  box-sizing: border-box;
  z-index: 99999;
  background: ${({ scroll }) =>
    scroll > 100 ? '#fff' : `url(${bg})`};
  background-size: cover;
  background-position: center;
  background-repeat: no-repeat;
  font-family: 'Comfortaa', sans-serif;
  transition: transform 0.2s ease-out, opacity 0.2s ease-out;

  @media (max-width: 768px) {
    height: 60px;
    background: #fff;
  }
`

const Img = styled.img`
  height: ${({ scroll }) => (scroll > 100 ? '50px' : '60px')};
  object-fit: contain;
  @media (max-width: 768px) {
    height: 35px;
    padding-left: 10px;
  }
`

const Menu = styled.div`
  grid-column: 2 / 3;
  z-index: 11;
  max-width: 100vw;
  box-sizing: border-box;
  display: flex;
  justify-content: space-between;
  align-items: center;
  box-sizing: border-box;
  max-width: 100vw;
  padding: 5px 0;
  background: transparent;
  width: 100%;
  position: relative;
`

const Ul = styled.ul`
  padding: 0;
  display: flex;
  align-items: center;
  list-style: none;
  transition: all 1s ease-out;
  @media (max-width: 768px) {
    background: #fff;
    position: absolute;
    margin: 0;
    width: 80vw;
    height: 100vh;
    transition: all 0.5s ease-out;
    flex-direction: column;
    boz-sizing: border-box;
    align-items: flex-start;
    padding-top: 50px;
    top: 0;
    right: 0;
    ${props =>
      props.togleBar === false
        ? 'transform: translateX(130%);'
        : 'transform: translateX(0%);'}
  }
`

const Hamburguer = styled.div`
  font-family: 'Quicksand', sans-serif;
  font-weight: lighter;
  color: ${({ theme }) => theme.white};
  font-size: 1.5rem;
  font-weight: 100;
  margin-top: 10px;
  margin-right: 25px;
  padding-bottom: 10px;
  display: none;
  color: #888888;
  @media (max-width: 768px) {
    z-index: 12;
    display: block;
  }
`

const Li = styled.li`
  margin: 0 15px;
  font-size: 16px;
  font-family: 'Comfortaa', sans-serif;
  text-transform: uppercase;
  font-weight: 100;
  position: relative;
  display: flex;
  @media (max-width: 768px) {
    margin-top: 20px;
    display: flex;
    flex-direction: column;
  }
  & > a {
    font-family: 'Open Sans', sans-serif;
    text-decoration: none;
    color: ${({ scroll }) => (scroll > 100 ? '#000' : '#fff')};
    font-size: 16px;
    text-align: center;
    cursor: pointer;
    &::after {
      content: '';
      width: 100%;
      height: 0;
      border: 2px solid transparent;
      display: block;
      transition: transform 0.2s ease-out, opacity 0.2s ease-out;
      opacity: 0;
      transform-origin: left;
      transform: scaleX(0);
    }
    &:hover,
    &.active {
      &::after {
        border-top-width: 2px;
        border-top-style: solid;
        border-top-color: rgba(0, 152, 245, 1);
        transform: scaleX(1);
        opacity: 1;
      }
    }

    @media (max-width: 768px) {
      font-size: 1rem;
      color: #888888;

      &:hover,
      &.active {
        color: #000;
        &::after {
          border-top-color: rgba(0, 0, 0, 1);
        }
      }
    }
  }

  & > svg {
    font-size: 1.2rem;
    margin-left: 5px;
    color: #fff;
    @media (max-width: 768px) {
      color: #888888;
    }
  }

  &:hover {
    & > svg {
      transform: rotate(180deg);
      transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    }
  }
`

const Span = styled.span`
  font-family: 'Open Sans', sans-serif;
  text-decoration: none;
  color: ${({ scroll }) => (scroll > 100 ? '#000' : '#fff')};
  font-size: 16px;
  text-align: center;
  cursor: pointer;
  &::after {
    content: '';
    width: 100%;
    height: 0;
    border: 2px solid transparent;
    display: block;
    transition: transform 0.2s ease-out, opacity 0.2s ease-out;
    opacity: 0;
    transform-origin: left;
    transform: scaleX(0);
  }
  &:hover,
  &.active {
    &::after {
      border-top-width: 2px;
      border-top-style: solid;
      border-top-color: rgba(0, 152, 245, 1);
      transform: scaleX(1);
      opacity: 1;
    }
  }

  @media (max-width: 768px) {
    font-size: 1rem;
    color: #888888;
    text-align: left;

    &:hover,
    &.active {
      color: #000;
      &::after {
        border-top-color: rgba(0, 0, 0, 1);
      }
    }
  }
`

const SubMenu = styled.ul`
  list-style: none;
  transition: all 0.2s;
  transform: ${props =>
    props.togleSubmenu ? 'scaleY(1)' : 'scaleY(0)'};
  transform-origin: 0 0 0;
  background-color: #fff;
  position: absolute;
  top: 100%;
  left: 0%;
  padding: 0;
  margin: 0;
  @media (max-width: 768px) {
    position: static;
    height: ${props => (props.togleSubmenu ? 'auto' : '0')};
  }
`

const SubLink = styled(HashLink)`
  align-items: center;
  font-size: 15px;
  text-decoration: none;
  text-transform: capitalize;
  position: relative;
  display: flex;
  color: #9f9f9f;
  padding: 10px 15px;
  border: 1px solid transparent;
  border-bottom-color: #9f9f9f;
  white-space: nowrap;
  &:hover {
    color: #000;
    background-color: #ececec;
  }
  @media (max-width: 768px) {
    color: #888888;
    border: none;
  }
`
