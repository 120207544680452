import React, { useState } from 'react'
import styled from 'styled-components'

import { connect } from 'react-redux'
import { setUser } from '../../actions'

import Button from '../../components/Button'
import video from '../../assets/static/video.mp4'
import logo from '../../assets/static/logo.svg'

const Login = props => {
  const [user, setUser] = useState({})
  const handlerInput = event => {
    setUser({
      ...user,
      [event.target.name]: event.target.value,
    })
  }
  const hanlderSubmit = event => {
    event.preventDefault()
    props.setUser(user, '/dashboard')
  }
  return (
    <LoginStyled>
      <VideoContainer>
        <Video autoPlay muted controls={false} loop>
          <source src={video} type="video/mp4" />
        </Video>
        <Text>
          "Llegar juntos es el principio. Mantenerse juntos, es el
          progreso. Trabajar juntos es el éxito". <br /> - Henry Ford.
        </Text>
      </VideoContainer>
      <Info>
        <Container>
          <Form onSubmit={hanlderSubmit}>
            <Img src={logo} alt="logo" />
            <h2 className="-small">Bienvenido a Formec Connect</h2>
            <p>Por favor ingresa tu usuario y contraseña</p>
            <p>Si no tienes cuenta, contacta al área de sistemas</p>
            <Hr />
            <InputContainer>
              <label htmlFor="user">
                <span>Usuario</span>
                <input
                  type="text"
                  name="user"
                  placeholder='Ej. "jose.perez"'
                  onChange={handlerInput}
                />
              </label>
              <label htmlFor="password">
                <span>Password</span>
                <input
                  type="password"
                  placeholder='Ej. "123456"'
                  name="password"
                  onChange={handlerInput}
                />
              </label>
            </InputContainer>
            <Hr />
            <Button color="primary" type="submit">
              Iniciar Sesión
            </Button>
          </Form>
        </Container>
      </Info>
    </LoginStyled>
  )
}

const mapDispatchToProps = {
  setUser,
}

export default connect(null, mapDispatchToProps)(Login)

const LoginStyled = styled.div`
  width: 100vw;
  height: 100vh;
  display: grid;
  grid-template-columns: 1fr 1fr 1fr;
`

const Info = styled.div`
  grid-column: 2/4;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: white;
`

const Container = styled.div`
  display: flex;
  width: 70%;
  @media (max-width: 680px) {
    display: flex;
    flex-direction: column-reverse;
    justify-content: center;
    align-items: center;
    border-radius: 15px;
    margin: 0 1rem;
    box-shadow: var(--boxShadowHover);
    height: auto;
  }
`

const Form = styled.form`
  width: 100%;
  h1 {
    color: var(--white);
  }

  button {
    margin-top: 1rem;
    float: right;
  }
  label {
    display: flex;
    flex-direction: column;
    margin-right: 50px;
    width: 100%;
    & > span {
      color: var(--white);
      font-size: 12px;
      padding-bottom: 0.5rem;
      padding-top: 0.5rem;
    }
    & > input {
      display: block;
      width: 100%;
      height: calc(1.25rem + 2px);
      padding: 0.375rem 0.75rem;
      font-size: 1rem;
      font-weight: 400;
      line-height: 1.5;
      color: #495057;
      background-color: #fff;
      background-clip: padding-box;
      border: 1px solid #ced4da;
      border-radius: 0.25rem;

      &:focus {
        outline-color: var(--gray);
      }
    }
  }
  @media (max-width: 680px) {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    > button {
      margin-bottom: 2rem;
    }
  }
`

const VideoContainer = styled.div`
  grid-column: 1/2;
  width: 100%;
  height: 100%;
  object-fit: cover;
  position: relative;
  &::before {
    content: '';
    position: absolute;
    top: 0;
    right: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(0, 72, 124, 0.8);
    z-index: 1;
  }
`

const Video = styled.video`
  position: absolute;
  top: 0;
  right: 0;
  width: 100%;
  height: 100%;
  object-fit: cover;
`

const Img = styled.img`
  width: 200px;
  margin-bottom: 50px;
`

const InputContainer = styled.div`
  width: 100%;
  display: flex;
`

const Hr = styled.hr`
  margin-top: 1rem;
  margin-bottom: 1rem;
  height: 1px;
  background-color: #e9ecef;
  border: none;
`

const Text = styled.p`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 1;
  color: white;
  font-size: 1.75rem;
  font-weight: 400;
  margin-bottom: 1.5rem;
  text-align: center;
  line-height: 1.5;
`
