import React from 'react'
import styled from 'styled-components'
import Title from '../../components/Title'
import hecho from '../../assets/static/hecho.svg'

const About = () => {
  return (
    <Main>
      <Container>
        <Img src={hecho} alt="hecho en mexico" />
        <Title size={'small'} color={'white'}>
          Somos una empresa 100% mexicana dedicada a ofrecer
          soluciones industriales y comerciales mediante la
          fabricación de piezas de plástico por los procesos de roto
          moldeo e inyección, ofreciendo siempre altos estándares de
          calidad y eficiencia en nuestros procesos.
        </Title>
      </Container>
    </Main>
  )
}

export default About

const Main = styled.main`
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  background-color: ${({ theme }) => theme.secondary};
  position: relative;
  color: #fff;
`

const Container = styled.div`
  grid-column: 2/3;
  display: flex;
  padding: 75px 0;
  text-align: center;
  align-items: center;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 50px 15px;
  }
`

const Img = styled.img`
  filter: invert(50%) sepia(95%) saturate(3346%) hue-rotate(181deg)
    brightness(92%) contrast(110%);
  height: 120px;
  margin-right: 75px;
  @media (max-width: 768px) {
    height: 200px;
    margin: 0 0 25px 0;
  }
`
