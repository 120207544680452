import React, { useState } from 'react'
import styled from 'styled-components'
import { BsChevronCompactDown } from 'react-icons/bs'

const Card = ({ title, children, color }) => {
  const [open, setOpen] = useState(false)
  return (
    <CardStyled color={color}>
      <CardTop onClick={() => setOpen(!open)}>
        {title}
        <Icon>
          <BsChevronCompactDown />
        </Icon>
      </CardTop>

      <CardBody open={open}>{children}</CardBody>
    </CardStyled>
  )
}
export default Card

const CardStyled = styled.div`
  background-color: ${({ theme }) => theme.backgroundButton};
  box-shadow: var(--boxShadow);
  border-radius: 5px;
  max-width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  border: ${({ theme }) => theme.border};
  box-shadow: ${({ theme }) => theme.boxShadow};
  margin-bottom: 20px;
  padding: 1rem 1.5rem;
  ${({ theme, color }) =>
    color && color === 'primary'
      ? `background-color: ${theme.primary};
         color: ${theme.menu_link_active};
         border-color: ${theme.menu_link_active};`
      : ''}
`

const CardBody = styled.div`
  display: ${({ open }) => (open ? 'block' : 'none')};

  background: none;
`

const CardTop = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 0 15px;
  border: ${({ theme }) => theme.border};
  position: relative;
  cursor: pointer;
  padding-right: 35px;
`

const Icon = styled.div`
  position: absolute;
  right: 15px;
`
