import React from 'react'
import styled from 'styled-components'
import Banner from '../../components/Banner'
import Title from '../../components/Title'
import Button from '../../components/Button'
import Hr from '../../components/Hr'
import Paragraph from '../../components/Paragraph'
import img1 from '../../assets/static/capacity_img1.png'
import { Link } from 'react-router-dom'
import img2 from '../../assets/static/capacity_img2.jpg'
import img3 from '../../assets/static/capacity_img3.jpg'
import img4 from '../../assets/static/capacity_img4.png'
const About = () => {
  return (
    <Main>
      <Banner>
        <>
          CAPACIDAD INSTALADA
          <Hr color="primary" />
          <Title size="small" color="white">
            Nuestra planta cuenta con una infraestructura de más de
            5,500 m2 para contar con los mejores y más optimizados
            procesos de producción.
          </Title>
        </>
      </Banner>
      <Container>
        <Article1>
          <Grid2>
            <Left>
              <Title size="mid" color="black">
                1 Máquina shuttle
              </Title>
              <Title size="small" color="black">
                Horno central y dos estaciones de enfriamiento.
              </Title>
              <Hr align={'left'} />
              <Paragraph>
                Nuestra roto-moldeadora tipo shuttle tiene una
                capacidad de 2.4 metros de volteo. Trabaja con un
                horno central y dos estaciones de enfriamiento
                mediante dos carros con brazos rectos. Este equipo
                ofrece una relación de productividad / economía, ya
                que permite rapidez en cambios de molde y trabajar con
                una o las dos estaciones.
              </Paragraph>
              <Paragraph>
                Su producción puede configurarse para correr varios y
                diferentes moldes a la vez y tiene la capacidad de
                correr con procesos de tiempo y temperatura diferentes
                en cada estación. Su proceso es automático, ya que se
                opera con un procesador integrado con el software de
                control de la máquina que proporciona un control de
                procesos de alta calidad.
              </Paragraph>
            </Left>
            <div>
              <Img src={img1} alt="" />
            </div>
          </Grid2>
        </Article1>
        <Article4>
          <Grid2>
            <div>
              <Img src={img2} alt="" />
            </div>
            <Left>
              <Title size="mid" color="black">
                4 Máquinas carrousel
              </Title>
              <Title size="small" color="black">
                Carros independientes para una producción secuencial a
                gran escala.
              </Title>
              <Hr align={'left'} />
              <Paragraph>
                Contamos con cuatro roto-moldeadoras tipo carrossel
                con capacidad de 1.9 a 3.0 mts. de volteo. Trabaja con
                un eje central sobre el cual giran los brazos pasando
                por las diferentes etapas del proceso de producción.
                Contamos con máquinas con tres y cuatro brazos
                independientes. Estos equipos ofrecen una excelente
                relación productividad / economía por su alta
                capacidad de producción secuencial de piezas en
                grandes cantidades.
              </Paragraph>
              <Paragraph>
                Por sus estaciones independientes, los procesos de
                horneado; pre-enfriamiento; enfriamiento; carga y
                descarga, pueden ser realizadas de manera individual
                dando la máxima eficiencia del tiempo máquina, ya que
                la máquina no para. Además, por sus sofisticados
                procesadores y el software adecuado a cada máquina,
                cada estación puede tener un proceso de producción con
                tiempos y temperaturas diferentes, pero manteniendo un
                comportamiento controlado dando como resultado piezas
                de calidad.
              </Paragraph>
            </Left>
          </Grid2>
        </Article4>
        <Article1>
          <Grid2>
            <Left>
              <Title size="mid" color="black">
                4 Máquina de inyección de plástico
              </Title>
              <Title size="small" color="black">
                De 130 a 990 ton. para altos volúmenes de producción.
              </Title>
              <Hr align={'left'} />
              <Paragraph>
                Contamos con cuatro máquinas de moldeo por inyección
                con un proceso estándar que funde material plástico el
                cual se inyecta en un molde que le da la forma. Es un
                proceso que requiere una sincronización perfecta entre
                tiempos y movimientos. Podemos inyectar hasta 2.5 kg
                de plástico en platinas de hasta 1.53 mt entre barras.
              </Paragraph>
            </Left>
            <div>
              <Img src={img3} alt="" />
            </div>
          </Grid2>
        </Article1>
        <Article3>
          <Left>
            <Float src={img4} alt="" />
            <Box>
              <Title size="small" color="white">
                En FORMEC nos gusta ver a nuestros clientes como
                socios comerciales y desarrollamos soluciones a la
                medida de sus necesidades
              </Title>
              <Link to="/solutions">
                <Button>VER SOLUCIONES</Button>
              </Link>
            </Box>
          </Left>
          <Left>
            <Box>
              <Title size="mid" color="secondary">
                ¿Tienes una idea que te gustaría desarrollar?
              </Title>
              <Title size="small" color="black">
                Juntos hagámosla realidad
              </Title>
              <Link to="/contact">
                <Button>PONERSE EN CONTACTO</Button>
              </Link>
            </Box>
          </Left>
        </Article3>
      </Container>
    </Main>
  )
}

export default About

const Main = styled.main``
const Container = styled.div`
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  background-color: ${({ theme }) => theme.white};

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Article1 = styled.article`
  grid-column: 2/3;
  margin: 40px 0;

  @media (max-width: 768px) {
    grid-column: 1/2;
    padding: 0 20px;
  }
`

const Grid2 = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 40px;

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
  }
`

const Left = styled.div`
  display: flex;
  flex-direction: column;
`

const Img = styled.img`
  width: 100%;
  height: 100%;
  object-fit: contain;
`

const Article3 = styled.article`
  grid-column: 1/4;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;

  @media (max-width: 768px) {
    flex-direction: column;
  }
  & > div {
    width: 100%;
    position: relative;
    @media (max-width: 768px) {
      align-items: center;
      box-sizing: border-box;
      padding: 20px;
    }
  }
  & > div:nth-child(1) {
    background-color: ${({ theme }) => theme.bgWeb};
    display: flex;
    align-items: flex-end;
    padding: 50px 0;
    @media (max-width: 768px) {
      align-items: center;
      box-sizing: border-box;
      padding: 20px;
    }
  }
`

const Box = styled.div`
  width: 450px;
  margin: 0 25px;
  text-align: center;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const Article4 = styled.article`
  grid-column: 1/4;
  margin-bottom: 30px;
  background-color: ${({ theme }) => theme.grayLight};
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  padding: 50px 0;
  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    box-sizing: border-box;
    padding: 0 20px;
  }
  & > div {
    grid-column: 2/3;
    display: grid;
    grid-template-columns: 1fr 1fr;
    grid-gap: 50px;
    @media (max-width: 768px) {
      grid-column: 1/4;
      grid-template-columns: 1fr;
      box-sizing: border-box;

    }
  }
`

const Float = styled.img`
  position: absolute;
  top: -71px;
  left: 0;
  height: 300px;
  @media (max-width: 768px) {
    display: none;
  }
`
