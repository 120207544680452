import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  setTitle,
  getAll,
  create,
  update,
  deleted,
} from '../../actions'
import Card from '../../components/Card'
import styled from 'styled-components'
import Tab from '../../components/Tab'
import box from '../../assets/static/box.jpg'
// import Input from '../../components/Input'
import Select from '../../components/Select'

const Products = props => {
  const { setTitle, catalog, customers, user } = props
  // const [data, setData] = useState([])
  const [customer, setCustomer] = useState(0)

  useEffect(() => {
    const topbar = {
      title: 'Catalogo de Productos',
    }
    setTitle(topbar)
    props.getAll('portal/catalog', 'GET_CATALOG').then(() => {
      props.getAll('customers', 'GET_CUSTOMERS')
    })

    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (user) {
      parseInt(user.role_id) === 1
        ? setCustomer(0)
        : setCustomer(parseInt(user.id))
    }
    // eslint-disable-next-line
  }, [user])

  if (!catalog || !customers) {
    return <h1>Cargando...</h1>
  }

  /*   const handleSearch = value => {
    if (value === '') {
      setData(catalog)
      return
    }
    const result = catalog.filter(item => {
      return item.model.toLowerCase().includes(value.toLowerCase())
    })
    setData(result)
  } */

  const finalData = catalog.filter(item =>
    customer === 0 ? item : item.customer_id === customer
  )

  return (
    <>
      {parseInt(user.role_id) === 1 ? (
        <Card>
          <Select
            name="customer"
            label="Cliente"
            options={customers.map(item => ({
              value: item.CCODIGOCLIENTE,
              label: `${item.CCODIGOCLIENTE} - ${item.CRAZONSOCIAL}`,
            }))}
            onChange={e => setCustomer(parseInt(e.value))}
          />
        </Card>
      ) : null}
      {/*  <Card>
        <Input
          type="text"
          name="search"
          placeholder="Buscar"
          onChange={e => handleSearch(e.target.value)}
        />
      </Card> */}
      <Card>
        <HeadContainer>
          <Header>
            <p>Imagen</p>
            <p>Modelo</p>
            <p>Color</p>
            <p>Altura</p>
            <p>Peso</p>
            <p>Cantidad</p>
          </Header>
        </HeadContainer>
        {finalData
          .filter(i => i.amount > 0)
          .map(item => (
            <Tab
              key={item.id}
              title={
                <Row>
                  <p>
                    <Img
                      src={
                        item.model.file !== null
                          ? `${process.env.REACT_APP_API}public/models/${item.model.file}`
                          : box
                      }
                      alt={item.model.model}
                    />
                  </p>
                  <p>{item.model.model}</p>
                  <p>{item.color}</p>
                  <p>{item.model.height_cm} cm</p>
                  <p>{item.model.weight_kg} kg</p>
                  <p>{item.amount}</p>
                </Row>
              }
              // children={
              //   <>
              //     <Table>
              //       <Thead>
              //         <Tr>
              //           <Th>Color</Th>
              //           <Th>Cantidad</Th>
              //           <Th>Codigo de barras</Th>
              //         </Tr>
              //       </Thead>
              //       <Tbody>
              //         {item.products.length > 0 ? (
              //           item.products.map((product, index) => {
              //             return (
              //               <Tr key={index}>
              //                 <Td>{product.code}</Td>
              //                 <Td>{product.amount}</Td>
              //                 <Td>{product.barcode}</Td>
              //               </Tr>
              //             )
              //           })
              //         ) : (
              //           <Tr>
              //             <Td colSpan={3}>Sin existencias</Td>
              //           </Tr>
              //         )}
              //       </Tbody>
              //     </Table>
              //   </>
              // }
            />
          ))}
      </Card>
    </>
  )
}

const mapStateToProps = state => {
  return {
    user: state.reducerApp.user,
    catalog: state.reducerApp.catalog,
    customers: state.reducerApp.customers,
    themeApp: state.reducerApp.themeApp,
    localization: state.reducerApp.localization,
  }
}

const mapDispatchToProps = {
  setTitle,
  getAll,
  create,
  update,
  deleted,
}

export default connect(mapStateToProps, mapDispatchToProps)(Products)

/* const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border-spacing: 0;
  box-sizing: border-box;
`

const Thead = styled.thead`
  border: ${({ theme }) => theme.border};
`

const Tbody = styled.thead`
  border: ${({ theme }) => theme.border};
  > tr {
    ${props => (props.selected ? 'cursor: pointer;' : '')};
    :hover {
      background-color: ${props =>
        props.selected ? props.theme.menu_category : ''};
    }
  }
`

const Tr = styled.tr`
  border: ${({ theme }) => theme.border};
`
const Th = styled.th`
  padding: 0.75rem;
  text-align: left;
  font-size: 0.8rem;
  @media (max-width: 768px) {
    padding: 0.5rem;
    font-size: 0.7rem;
  }
`
const Td = styled.td`
  padding: 0.75rem;
  font-size: 0.8rem;
  background-color: ${props =>
    parseInt(props.delivered) === 1 ? props.theme.success : ''};
  @media (max-width: 768px) {
    padding: 0.5rem;
    font-size: 0.7rem;
  }
` */

const Img = styled.img`
  width: 100px;
`

const HeadContainer = styled.div`
  width: 100%;
  position: relative;
  height: 75px;
`

const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  padding: 0 25px;
`

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
`
