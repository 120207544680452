import React from 'react'
import styled from 'styled-components'

const Title = ({ children, color, size }) => {
  return (
    <TitleStyled color={color} size={size}>
      {children}
    </TitleStyled>
  )
}

export default Title

const TitleStyled = styled.div`
  font-weight: 700;
  font-family: 'Ubuntu', sans-serif;
  font-size: ${({ size }) =>
    size === 'big' ? '50px' : size === 'mid' ? '30px' : '18px'};
  margin-bottom: ${({ size }) =>
    size === 'big' ? '50px' : size === 'mid' ? '10px' : '10px'};
  letter-spacing: ${({ size }) =>
    size === 'big' ? '4px' : size === 'mid' ? '3px' : '1px'};
  color: ${props => {
    switch (props.color) {
      case 'secondary':
        return props.theme.secondary
      case 'white':
        return props.theme.white
      case 'info':
        return props.theme.info
      case 'success':
        return props.theme.success
      case 'black':
        return props.theme.black

      default:
        return props.theme.primary
    }
  }};

  @media (max-width: 768px) {
    font-size: ${({ size }) =>
      size === 'big' ? '30px' : size === 'mid' ? '25px' : '15px'};
    margin-bottom: ${({ size }) =>
      size === 'big' ? '30px' : size === 'mid' ? '25px' : '15px'};
    letter-spacing: ${({ size }) =>
      size === 'big' ? '4px' : size === 'mid' ? '3px' : '1px'};
  }
`
