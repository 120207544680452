import React, { useState, useEffect } from 'react'
import styles, { ThemeProvider } from 'styled-components'
import Topbar from '../containers/Topbar'
import Aside from '../containers/Aside'
import Container from '../containers/Container'
import Header from '../containers/Header'
import Footer from '../containers/Footer'
import { connect } from 'react-redux'
import { lightTheme, darkTheme } from '../assets/styles/theme'
import { GlobalStyles } from '../assets/styles/theme'
import { setThemeApp } from '../actions'

const Layout = ({ children, loggedIn, topbar, setThemeApp }) => {
  const [theme, setTheme] = useState('light')
  const [toggleBar, setToggleBar] = useState(false)
  const [toggleBarMouse, setToggleBarMouse] = useState(false)
  const isDarkTheme = theme === 'dark'
  const toggleTheme = () => {
    const updatedTheme = isDarkTheme ? 'light' : 'dark'
    setTheme(updatedTheme)
    localStorage.setItem('theme', updatedTheme)
  }
  const toggleBarButton = () => {
    setToggleBar(!toggleBar)
  }
  const toggleMouseHover = () => {
    setToggleBarMouse(!toggleBarMouse)
  }
  const title = topbar.title

  const buttonBar = topbar.buttonBar

  React.createContext(theme)

  useEffect(() => {
    const savedTheme = localStorage.getItem('theme')
    const prefersDark =
      window.matchMedia &&
      window.matchMedia('(prefers-color-scheme: dark)').matches

    if (savedTheme && ['dark', 'light'].includes(savedTheme)) {
      setTheme(savedTheme)
    } else if (prefersDark) {
      setTheme('dark')
    }
  }, [])

  useEffect(() => {
    setThemeApp({ theme, lightTheme, darkTheme })
  }, [setThemeApp, theme])

  console.log(window.location.pathname.includes('/dashboard'))

  return (
    <ThemeProvider theme={theme === 'light' ? lightTheme : darkTheme}>
      <GlobalStyles />
      {loggedIn === true ? (
        window.location.pathname.includes('/dashboard') ? (
          <Main toggleBar={toggleBar} toggleBarMouse={toggleBarMouse}>
            <Topbar
              onTheme={toggleTheme}
              theme={isDarkTheme}
              toggleBarButton={toggleBarButton}
              toggleBar={toggleBar}
              toggleBarMouse={toggleBarMouse}
            />
            <Aside
              theme={isDarkTheme}
              toggleBarButton={toggleBarButton}
              toggleBar={toggleBar}
              toggleBarMouse={toggleBarMouse}
              toggleMouseHover={toggleMouseHover}
            />
            <Container title={title} buttonBar={buttonBar}>
              {children}
            </Container>
          </Main>
        ) : window.location.pathname.includes('/login') ? (
          <>{children}</>
        ) : (
          <Web>
            <Header />
            {children}
            <Footer />
          </Web>
        )
      ) : !window.location.pathname.includes('/dashboard') &&
        !window.location.pathname.includes('/login') ? (
        <Web>
          <Header />
          {children}
          <Footer />
        </Web>
      ) : (
        <>{children}</>
      )}
    </ThemeProvider>
  )
}

const mapStateToProps = state => {
  return {
    loggedIn: state.reducerApp.loggedIn,
    topbar: state.reducerApp.topbar,
  }
}

const mapDispatchToProps = {
  setThemeApp,
}

export default connect(mapStateToProps, mapDispatchToProps)(Layout)

const Main = styles.main`
  display: grid;
  width: 100vw;
  transition: all 0.3s ease-in-out;
  grid-template-columns: ${props =>
    props.toggleBar && !props.toggleBarMouse ? '80px' : '270px'} 1fr;
  grid-template-rows: 75px 1fr;
  grid-template-areas:
    'aside topbar'
    'aside container';

  @media (max-width: 768px) {
    grid-template-columns: 1fr;
    grid-template-rows: 75px 1fr;
    grid-template-areas:
      'topbar'
      'container';
  }
`

const Web = styles.main`
  display: grid;
  width: 100vw;
  transition: all 0.3s ease-in-out;
  grid-template-columns: 1fr;
  box-sizing: border-box;
  overflow-x: hidden;
`
