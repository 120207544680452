import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import {
  setTitle,
  getAll,
  create,
  update,
  deleted,
} from '../../actions'
// import MaterialTable from 'material-table'
import { Link } from 'react-router-dom'
import Button from '../../components/Button'
import Tab from '../../components/Tab'
import DatePicker, {
  registerLocale,
  setDefaultLocale,
} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import es from 'date-fns/locale/es'
import moment from 'moment'
import Card from '../../components/Card'
import styled from 'styled-components'
import formatNumbers from '../../utils/formatNumbers'

registerLocale('es', es)
setDefaultLocale('es')

const Users = props => {
  const { setTitle, orders, user, customers } = props

  const [start, setStart] = useState(new Date())
  const [end, setEnd] = useState(null)
  const [generate, setGenerete] = useState(0)

  useEffect(() => {
    const topbar = {
      title: 'Pedidos',
      buttonBar: (
        <Link to="/dashboard/oc/create">
          <Button>Crear</Button>
        </Link>
      ),
    }
    setTitle(topbar)
    props.getAll('orders', 'GET_ORDERS').then(() => {
      props.getAll('customers', 'GET_CUSTOMERS')
    })

    // eslint-disable-next-line
  }, [])

  if (!orders || !user || !customers) {
    return <h1>Cargando...</h1>
  }

  const onChange = dates => {
    const [s, e] = dates
    setStart(s)
    setEnd(e)
  }

  const data = orders
    .filter(item => item.status === 0)
    .filter(item =>
      generate === 1
        ? moment(item.CFECHA).isBetween(moment(start), moment(end))
        : true
    )

  return (
    <>
      <Tab title="Filtro por fechas">
        <DatePicker
          selected={start}
          onChange={onChange}
          startDate={start}
          endDate={end}
          selectsRange
          locale="es-MX"
          inline
        />
        <Button onClick={() => setGenerete(1)}>Generar</Button>
        <Button onClick={() => setGenerete(0)}>Limpiar</Button>
      </Tab>
      <Card>
        <HeadContainer>
          <Header>
            <p>Folio</p>
            <p>Razón Social</p>
            <p>Fecha</p>
            <p>Estatus</p>
            {/* <p>Total</p> */}
          </Header>
        </HeadContainer>
        {data.map(item => {
          const customer = customers.find(
            customer =>
              parseInt(customer.CCODIGOCLIENTE) ===
              parseInt(item.customer_id)
          )
          return (
            <Tab
              key={item.id}
              title={
                <Row>
                  <p>{item.number}</p>
                  <p>{customer.CRAZONSOCIAL || ''}</p>
                  <p>{moment(item.delivery).format('DD-MM-YYYY')}</p>
                  <p>Recibida</p>
                  {/* <p>${formatNumbers(item.price * item.amount, 2)}</p> */}
                </Row>
              }
              children={
                <>
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>Código</Th>
                        <Th>Nombre</Th>
                        <Th>Color</Th>
                        <Th>Cantidad</Th>
                        <Th>Precio</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {item.items.length > 0 ? (
                        item.items.map((product, index) => {
                          return (
                            <Tr key={index}>
                              <Td>{product.barcode}</Td>
                              <Td>{product.model.model}</Td>
                              <Td>{product.color}</Td>
                              <Td>{product.amount}</Td>
                              <Td>
                                ${formatNumbers(product.price, 2)}
                              </Td>
                            </Tr>
                          )
                        })
                      ) : (
                        <Tr>
                          <Td colSpan={3}>Sin existencias</Td>
                        </Tr>
                      )}
                    </Tbody>
                  </Table>
                </>
              }
            />
          )
        })}
      </Card>
    </>
  )
}

const mapStateToProps = state => {
  return {
    orders: state.reducerApp.orders,
    user: state.reducerApp.user,
    customers: state.reducerApp.customers,
  }
}

const mapDispatchToProps = {
  setTitle,
  getAll,
  create,
  update,
  deleted,
}

export default connect(mapStateToProps, mapDispatchToProps)(Users)

const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border-spacing: 0;
  box-sizing: border-box;
`

const Thead = styled.thead`
  border: ${({ theme }) => theme.border};
`

const Tbody = styled.thead`
  border: ${({ theme }) => theme.border};
  > tr {
    ${props => (props.selected ? 'cursor: pointer;' : '')};
    :hover {
      background-color: ${props =>
        props.selected ? props.theme.menu_category : ''};
    }
  }
`

const Tr = styled.tr`
  border: ${({ theme }) => theme.border};
`
const Th = styled.th`
  padding: 0.75rem;
  text-align: left;
  font-size: 0.8rem;
  @media (max-width: 768px) {
    padding: 0.5rem;
    font-size: 0.7rem;
  }
`
const Td = styled.td`
  padding: 0.75rem;
  font-size: 0.8rem;
  background-color: ${props =>
    parseInt(props.delivered) === 1 ? props.theme.success : ''};
  @media (max-width: 768px) {
    padding: 0.5rem;
    font-size: 0.7rem;
  }
`

const HeadContainer = styled.div`
  width: 100%;
  position: relative;
  height: 75px;
`

const Header = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  padding: 0 25px;
`

const Row = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr 1fr 1fr 1fr;
  width: 100%;
  justify-content: center;
  align-items: center;
  font-size: 0.8rem;
`
