import React from 'react'
import LinearProgress from '@mui/material/LinearProgress'
import Typography from '@mui/material/Typography'
import Box from '@mui/material/Box'

function LinearProgressWithLabel(props) {
  return (
    <Box sx={{ display: 'flex', alignItems: 'center' }}>
      <Box sx={{ width: '100%', mr: 1 }}>
        <LinearProgress
          variant="determinate"
          {...props}
          value={props.value >= 100 ? 100 : props.value}
          sx={{
            height: 15,
          }}
        />
      </Box>
      <Box sx={{ minWidth: 35 }}>
        <Typography
          variant="body2"
          color="text.secondary"
        >{`${Math.round(props.value)}%`}</Typography>
      </Box>
    </Box>
  )
}

export default function LinearWithValueLabel({ data }) {
  return (
    <Box sx={{ width: '100%' }}>
      <LinearProgressWithLabel
        value={data}
        color={
          data >= 100 ? 'error' : data > 80 ? 'warning' : 'primary'
        }
      />
    </Box>
  )
}
