import React, { useEffect } from 'react'
import { connect } from 'react-redux'
import { setTitle, getAll, deleted } from '../../actions'
import styled from 'styled-components'
import circle from '../../assets/static/circle.svg'
import { CgBitbucket } from 'react-icons/cg'
import { Link } from 'react-router-dom'
import Card from '../../components/Card'
import moment from 'moment'
import {
  Chart as ChartJS,
  CategoryScale,
  LinearScale,
  PointElement,
  LineElement,
  BarElement,
  Title,
  Tooltip,
  Legend,
} from 'chart.js'
import { Bar } from 'react-chartjs-2'
import formatNumbers from '../../utils/formatNumbers'

ChartJS.register(
  CategoryScale,
  LinearScale,
  BarElement,
  Title,
  Tooltip,
  Legend,
  PointElement,
  LineElement
)
const options = {
  responsive: true,
  plugins: {
    legend: {
      position: 'top',
    },
    title: {
      display: true,
    },
  },
  interaction: {
    mode: 'index',
    intersect: false,
  },
  scales: {
    x: {
      stacked: true,
    },
    y: {
      stacked: true,
    },
  },
}

const Home = props => {
  const {
    setTitle,
    products,
    colors,
    models,
    families,
    categories,
    barcodes,
    productionReport,
    workArea,
    orders,
    shippings,
    suppliers,
    customers,
    stock,
  } = props

  useEffect(() => {
    const topbar = {
      title: 'Dashboard',
    }
    setTitle(topbar)
    props
      .getAll('products', 'GET_PRODUCTS')
      .then(() => {
        props.getAll('products/models', 'GET_MODELS')
      })
      .then(() => {
        props.getAll('products/colors', 'GET_COLORS')
      })
      .then(() => {
        props.getAll('products/families', 'GET_FAMILIES')
      })
      .then(() => {
        props.getAll('products/categories', 'GET_CATEGORIES')
      })
      .then(() => {
        props.getAll('products/barcodes', 'GET_BARCODES')
      })
      /*  .then(() => {
        props.getAll(
          `production/report/2022-10-01/`,
          'GET_PRODUCTION_REPORT'
        )
      }) */
      .then(() => {
        props.getAll(
          `production/report/${moment()
            .subtract(1, 'week')
            .format('YYYY-MM-DD')}/${moment().format('YYYY-MM-DD')}`,
          'GET_PRODUCTION_REPORT'
        )
      })
      .then(() => {
        props.getAll('workArea', 'GET_WORKAREA')
      })
      .then(() => {
        props.getAll('orders', 'GET_ORDERS')
      })
      .then(() => {
        props.getAll('shippings', 'GET_SHIPPINGS')
      })
      .then(() => {
        props.getAll('customers', 'GET_CUSTOMERS')
      })
      .then(() => {
        props.getAll('suppliers', 'GET_SUPPLIERS')
      })
      .then(() => {
        props.getAll('stock', 'GET_STOCK')
      })

    // eslint-disable-next-line
  }, [])

  if (
    !products ||
    !colors ||
    !models ||
    !families ||
    !categories ||
    !barcodes ||
    !productionReport ||
    !workArea ||
    !orders ||
    !shippings ||
    !suppliers ||
    !customers ||
    !stock
  ) {
    return <div>Cargando...</div>
  }

  return (
    <HomeStyled>
      <Grid>
        <Link to="/stock">
          <Item color="info">
            <Info>
              <Number>
                {formatNumbers(
                  stock.reduce((a, b) => a + b.amount, 0)
                )}
              </Number>
              <Text>Inventario</Text>
            </Info>
            <Icon />
          </Item>
        </Link>

        <Link to="/orders">
          <Item>
            <Info>
              <Number>{orders.length}</Number>
              <Text>Pedidos</Text>
            </Info>
            <Icon />
          </Item>
        </Link>
      </Grid>
      <Container>
        <ColumnContainer>
          <TitleC>Recibido</TitleC>
          {orders
            .filter(o => o.status === 0)
            .map((order, index) => {
              return (
                <Item2 key={index} color="primary">
                  <div>ID Pedido: {order.id}</div>
                  <div>Numero Pedido: {order.number}</div>
                  <div>
                    Fecha de entrega:{' '}
                    {moment(order.delivery).format('DD/MM/YYYY')}
                  </div>
                </Item2>
              )
            })}
        </ColumnContainer>
        <ColumnContainer>
          <TitleC>Planeación</TitleC>
          {orders
            .filter(o => o.status === 2)
            .map((order, index) => {
              return (
                <Item2 key={index} color="purple">
                  <div>ID Pedido: {order.id}</div>
                  <div>Numero Pedido: {order.number}</div>
                  <div>
                    Fecha de entrega:{' '}
                    {moment(order.delivery).format('DD/MM/YYYY')}
                  </div>
                </Item2>
              )
            })}
        </ColumnContainer>
        <ColumnContainer>
          <TitleC>Producción</TitleC>
          {orders
            .filter(o => o.status === 3)
            .map((order, index) => {
              return (
                <Item2 key={index} color="warning">
                  <div>ID Pedido: {order.id}</div>
                  <div>Numero Pedido: {order.number}</div>
                  <div>
                    Fecha de entrega:{' '}
                    {moment(order.delivery).format('DD/MM/YYYY')}
                  </div>
                </Item2>
              )
            })}
        </ColumnContainer>
        <ColumnContainer>
          <TitleC>Almacen</TitleC>
          {orders
            .filter(o => o.status === 4)
            .map((order, index) => {
              return (
                <Item2 key={index} color="danger">
                  <div>ID Pedido: {order.id}</div>
                  <div>Numero Pedido: {order.number}</div>
                  <div>
                    Fecha de entrega:{' '}
                    {moment(order.delivery).format('DD/MM/YYYY')}
                  </div>
                </Item2>
              )
            })}
        </ColumnContainer>
        <ColumnContainer>
          <TitleC>Embarques</TitleC>
          {orders
            .filter(o => o.status === 5)
            .map((order, index) => {
              return (
                <Item2 key={index} color="success">
                  <div>ID Pedido: {order.id}</div>
                  <div>Numero Pedido: {order.number}</div>
                  <div>
                    Fecha de entrega:{' '}
                    {moment(order.delivery).format('DD/MM/YYYY')}
                  </div>
                </Item2>
              )
            })}
        </ColumnContainer>
      </Container>
      {/* <Card title={'Producción ultimos 7 días'}>
        <Bar
          options={options}
          responsive={false}
          data={{
            labels: productionReport.graph
              .filter(
                (value, index, self) =>
                  self.findIndex(v => v.date === value.date) === index
              )
              .map(g => {
                return moment(g.date).format('DD/MM/YYYY')
              }),

            datasets: workArea.map((w, index) => {
              const labels = productionReport.graph.filter(
                (value, index, self) =>
                  self.findIndex(v => v.date === value.date) === index
              )

              return {
                label: w.name,
                data: labels.map(
                  i =>
                    productionReport.graph.find(
                      d =>
                        d.date === i.date && d.work_area_id === w.id
                    )?.total || 0
                ),
                backgroundColor: w.color,
                stack: index,
              }
            }),
          }}
        />
      </Card> */}
    </HomeStyled>
  )
}

const mapStateToProps = state => {
  return {
    stock: state.reducerApp.stock,
    orders: state.reducerApp.orders,
    customers: state.reducerApp.customers,
    shippings: state.reducerApp.shippings,
    suppliers: state.reducerApp.suppliers,
    products: state.products.products,
    categories: state.products.categories,
    productionReport: state.reducerApp.productionReport,
    workArea: state.reducerApp.workArea,
    models: state.products.models,
    families: state.products.families,
    barcodes: state.products.barcodes,
    colors: state.products.colors,
    user: state.reducerApp.user,
  }
}

const mapDispatchToProps = {
  setTitle,
  getAll,
  deleted,
}

export default connect(mapStateToProps, mapDispatchToProps)(Home)

const HomeStyled = styled.div`
  display: flex;
  flex-direction: column;
`

const Grid = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  gap: 1rem;
  padding-bottom: 1rem;
  @media (max-width: 768px) {
    grid-template-columns: 1fr 1fr;
    gap: 10px;

    img {
      max-width: 300px;
    }
  }
`

const Item = styled.div`
  position: relative;
  background-image: url(${circle});
  background-color: ${props => {
    switch (props.color) {
      case 'warning':
        return props.theme.warning
      case 'danger':
        return props.theme.danger
      case 'info':
        return props.theme.info
      case 'success':
        return props.theme.success
      case 'secondary':
        return props.theme.secondary
      case 'purple':
        return props.theme.purple

      default:
        return props.theme.primary
    }
  }};
  color: #fff;
  border: 1px solid
    ${props => {
      switch (props.color) {
        case 'warning':
          return props.theme.warning
        case 'danger':
          return props.theme.danger
        case 'info':
          return props.theme.info
        case 'success':
          return props.theme.success
        case 'secondary':
          return props.theme.secondary
        case 'purple':
          return props.theme.purple
        default:
          return props.theme.primary
      }
    }};
  background-size: contain;
  background-position: right;
  background-repeat: no-repeat;
  display: flex;
  justify-content: space-between;
  padding: 1.5rem;
  border-radius: 10px;
`
const Info = styled.div`
  display: flex;
  flex-direction: column;
`

const Number = styled.span`
  line-height: 1.1;
  font-size: 1.5rem;
  font-weight: 500;
`
const Text = styled.span`
  font-size: 0.9rem;
  font-weight: 100;
`

const Icon = styled(CgBitbucket)`
  font-size: 30px;
`

const Container = styled.div`
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  grid-gap: 1rem;
`

const ColumnContainer = styled.div`
  display: flex;
  flex-direction: column;
  gap: 1rem;
`

const TitleC = styled.h2`
  text-align: center;
  margin: 0;
  font-size: 1.2rem;
  font-weight: 300;
`

const Item2 = styled.div`
  position: relative;
  color: ${props => props.theme.text};
  background-color: ${props => props.theme.backgroundButton};
  padding: 1rem;
  border-radius: 5px;
  box-shadow: 0 0 5px rgba(0, 0, 0, 0.2);
  min-height: 80px;
  border-bottom: 8px solid
    ${props => {
      switch (props.color) {
        case 'warning':
          return props.theme.warning
        case 'danger':
          return props.theme.danger
        case 'info':
          return props.theme.info
        case 'success':
          return props.theme.success
        case 'secondary':
          return props.theme.secondary
        case 'purple':
          return props.theme.purple
        default:
          return props.theme.primary
      }
    }};
`
