import React from 'react'
import styled from 'styled-components'
import Banner from '../../components/Banner'
import Title from '../../components/Title'
import Button from '../../components/Button'
import Hr from '../../components/Hr'
import Paragraph from '../../components/Paragraph'
import img1 from '../../assets/static/products_img1.jpg'
import img2 from '../../assets/static/products_img2.png'
import img3 from '../../assets/static/products_img3.png'
import img4 from '../../assets/static/products_img4.png'
import img5 from '../../assets/static/products_img5.png'
import img6 from '../../assets/static/products_img6.png'
import img7 from '../../assets/static/products_img7.png'
import img8 from '../../assets/static/products_img8.png'
import { Link } from 'react-router-dom'
const About = () => {
  return (
    <Main>
      <Banner>
        <>
          NUESTROS PRODUCTOS
          <Hr color="primary" />
          <Title size="small" color="white">
            Desarrollamos y creamos piezas de plásticos para sectores
            muy diversos.
          </Title>
        </>
      </Banner>
      <Article2>
        <Title color="white" size="small">
          En FORMEC, hemos decidido producir artículos de calidad
          respaldados por nuestra capacitación y honestidad personal,
          asegurando el cumplimiento de los requerimientos de nuestros
          clientes, mediante la revisión y mejora continua de los
          procesos de nuestro sistema de calidad.
        </Title>
      </Article2>
      <Article1 id="macetas">
        <div>
          <Img src={img1} alt="" />
        </div>
        <Content>
          <Title size="mid" color="primary">
            Macetas
          </Title>
          <LogoImg>
            <Logo src={img2} alt="" />
            <div>
              <Paragraph>
                Las macetas que elaboramos en FORMEC cuentan con un
                diseño exclusivo garantizando durabilidad,
                flexibilidad, ligereza , no se decolora con el sol, y
                son resistentes al calor, esto derivado de la calidad
                de nuestros materiales utilizados en el proceso de
                rotomoldeo.
              </Paragraph>
              <Paragraph>
                Nuestras macetas las fabricamos en diferentes
                materiales (hdpe, pvc, pp]) y en diferentes procesos
                (roto moldeo e inyección).
              </Paragraph>
            </div>
          </LogoImg>
        </Content>
      </Article1>
      <Article1 id="banos">
        <Content>
          <Title size="mid" color="primary">
            Baños portátiles
          </Title>
          <LogoImg>
            <Logo src={img3} alt="" />
            <div>
              <Paragraph>
                Somos una empresa pionera en la fabricación de baños
                en México, podemos elaborarlos en una variedad de
                colores conforme a las necesidades de nuestros
                clientes, los materiales utilizados son de la mejor
                calidad esto incluye los mejores herrajes para su
                ensamblado lo anterior permite que la durabilidad sea
                mayor.
              </Paragraph>
              <Paragraph>
                Contamos con tecnología que permite que nuestros
                productos sean más factibles de dar limpieza y
                mantenerlos libres de gérmenes por el uso y paso del
                tiempo.
              </Paragraph>
            </div>
          </LogoImg>
          <Paragraph />
        </Content>
        <div>
          <Img src={img4} alt="" />
        </div>
      </Article1>
      <Article1 id="sena">
        <div>
          <Img src={img5} alt="" />
        </div>
        <Content>
          <Title size="mid" color="primary">
            Señalamientos
          </Title>
          <LogoImg>
            <div>
              <Paragraph>
                Nuestros señalamientos son fáciles de armar y desarmar
                permiten disminuir el riesgo de accidentes. Por su
                flexibilidad cumplen con los estándares de seguridad
                establecidos, son resistentes a altas y bajas
                temperaturas manteniendo su color y durabilidad.
              </Paragraph>
              <Paragraph>
                Son ligeros y fáciles de cargar, de transportar y
                descargar. Su almacenaje es práctico y sencillo a
                diferencia de utilizar algunos de otro material que
                son muy pesados.
              </Paragraph>
            </div>
          </LogoImg>
        </Content>
      </Article1>
      <Article1 id="tarimas">
        <Content>
          <Title size="mid" color="primary">
            Tarimas y sistemas de almacenamiento
          </Title>
          <LogoImg>
            <Logo src={img6} alt="" />
            <div>
              <Paragraph>
                La ROTO – TARIMA FORMEC tienen la ventaja de poder
                fabricarse para soportar distintos pesos dependiendo
                de las necesidades del trabajo a que se va a someter y
                en el caso de sufrir algún daño permiten cambiar la
                sección dañada sin tener que deshacerse de la tarima
                completa.
              </Paragraph>
              <Paragraph>
                Por su fácil reparación en caso de mal uso, o
                accidente, permite a nuestros clientes tener un ahorro
                en sus costos de estibamiento y movimiento de producto
                por daños en las tarimas.
              </Paragraph>
            </div>
          </LogoImg>
          <Paragraph />
        </Content>
        <div>
          <Img src={img7} alt="" />
        </div>
      </Article1>
      <Article1 id="contenedores">
        <div>
          <Img src={img8} alt="" />
        </div>
        <Content>
          <Title size="mid" color="primary">
            Contenedores y piezas industriales
          </Title>
          <LogoImg>
            <div>
              <Paragraph>
                FORMEC elabora contenedores de acuerdo a la necesidad
                del cliente los cuales permiten almacenar distintas
                capacidades y tipos de productos logrando sustituir
                productos de metal haciendo más fácil su traslado,
                carga, colocación.
              </Paragraph>
              <Paragraph>
                En nuestro departamento de ingeniería y diseño,
                podemos desarrollar el contenedor que nuestros
                clientes requieren.
              </Paragraph>
            </div>
          </LogoImg>
        </Content>
      </Article1>
      <Article3>
        <Content2>
          <Title size="big" color="secondary">
            ¿Tienes una idea que <br /> te gustaría desarrollar?
          </Title>
          <Title size="mid" color="black">
            Juntos hagámosla realidad
          </Title>
          <Link to="/contact">
            <Button>PONERSE EN CONTACTO</Button>
          </Link>
        </Content2>
      </Article3>
    </Main>
  )
}

export default About

const Main = styled.main`
  background-color: #fff;
`

const Article1 = styled.article`
  display: flex;

  & > :nth-of-type(odd) {
    background-color: ${props => props.theme.grayLight};
    @media (max-width: 768px) {
      flex-direction: column;
    }
  }

  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const Article2 = styled.article`
  background-color: ${props => props.theme.bgWeb};
  padding: 50px 100px;
  text-align: center;
`

const Content = styled.div`
  background-color: #fff;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: flex-start;
  padding: 75px;
  @media (max-width: 768px) {
    align-items: center;
  }
`

const Content2 = styled.div`
  background-color: #fafafa;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  text-align: center;
`

const Img = styled.img`
  width: 40vw;
  height: 100%;
  object-fit: cover;
  @media (max-width: 768px) {
    width: 100%;
  }
`

const Article3 = styled.article`
  grid-column: 1/4;
  display: flex;
  justify-content: center;
  align-items: center;
  box-sizing: border-box;
  padding: 50px;
  background-color: ${props => props.theme.grayLight};
  text-align: center;
  @media (max-width: 768px) {
    flex-direction: column;
  }
`

const Logo = styled.img`
  width: 200px;
  object-fit: contain;
  margin-right: 15px;
  @media (max-width: 768px) {
    text-align: center;
    margin-right: 0;
  }
`

const LogoImg = styled.div`
  display: flex;
  @media (max-width: 768px) {
    flex-direction: column;
    align-items: center;
  }
`
