import React from 'react'
import styled from 'styled-components'

const Hr = ({ color, align }) => {
  return <HrStyled color={color} align={align} />
}

export default Hr

const HrStyled = styled.hr`
  width: 400px;
  height: 2px;
  border: 0;
  background-color: ${({ color, theme }) =>
    color === 'primary' ? theme.primary : theme.secondary};

  ${({ align }) =>
    align === 'left'
      ? `margin-left: 0;`
      : align === 'right'
      ? `margin-right: 0;`
      : ''}

  @media (max-width: 768px) {
    width: calc(100vw - 40px);
  }
`
