import React from 'react'
import styled from 'styled-components'
import Title from '../../components/Title'
import macetas from '../../assets/static/macetas.png'
import senalamientos from '../../assets/static/senalamientos.png'
import banos from '../../assets/static/banos.png'
import contenedores from '../../assets/static/contenedores.png'
import pallets from '../../assets/static/pallets.png'
import Button from '../../components/Button'

const Solutions = () => {
  return (
    <Main>
      <Container>
        <Title size={'mid'} color="secondary">
          Soluciones a la medida y equipo de alta tecnología.
        </Title>
        <P>
          Fabricamos macetas, baños portátiles, tarimas y artículos
          industriales en sistemas de rotomoldeo e inyección de
          tamaños, chico, mediano y grande, durables, de uso rudo,
          acorde a tus necesidades.
        </P>
        <Grid>
          <Item>
            <Img src={macetas} alt="macetas" />
            <Title size={'small'} color="black">
              Macetas
            </Title>
          </Item>
          <Item>
            <Img src={banos} alt="banos" />
            <Title size={'small'} color="black">
              Baños portátiles
            </Title>
          </Item>
          <Item>
            <Img src={senalamientos} alt="senalamientos" />
            <Title size={'small'} color="black">
              Señalamientos
            </Title>
          </Item>
          <Item>
            <Img src={pallets} alt="pallets" />
            <Title size={'small'} color="black">
              Tarimas
            </Title>
          </Item>
          <Item>
            <Img src={contenedores} alt="contenedores" />
            <Title size={'small'} color="black">
              Contenedores
            </Title>
          </Item>
        </Grid>
        <ButtonContiner>
          <Button>Ver Productos</Button>
        </ButtonContiner>
      </Container>
    </Main>
  )
}

export default Solutions

const Main = styled.main`
  width: 100vw;
  display: grid;
  grid-template-columns: 1fr minmax(375px, 1200px) 1fr;
  background-color: ${({ theme }) => theme.white};
  position: relative;
`

const Container = styled.div`
  grid-column: 2/3;
  display: grid;
  grid-template-columns: 550px 1fr;
  padding: 75px 0;
  gap: 10px;
  @media (max-width: 768px) {
    display: flex;
    flex-direction: column;
    padding: 50px 15px;
  }
`

const P = styled.p``

const Grid = styled.div`
  grid-column: 1/3;
  display: grid;
  grid-template-columns: repeat(5, 1fr);
  max-width: 100%;
  margin: 50px 0;
  @media (max-width: 768px) {
    grid-template-columns: repeat(2, 1fr);
  }
`

const Item = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
`

const Img = styled.img`
  width: 150px;
`

const ButtonContiner = styled.div`
  grid-column: 1/3;
  display: flex;
  align-items: center;
  justify-content: center;
`
