import React, { useEffect, useState } from 'react'
import { connect } from 'react-redux'
import { Link } from 'react-router-dom'
import { create, getAll, setTitle } from '../../actions'
import Card from '../../components/Card'
import Input from '../../components/Input'
import Button from '../../components/Button'
import styled from 'styled-components'
import Select from '../../components/Select'
import Swal from 'sweetalert2'
import DatePicker, {
  registerLocale,
  setDefaultLocale,
} from 'react-datepicker'
import 'react-datepicker/dist/react-datepicker.css'
import es from 'date-fns/locale/es'
import moment from 'moment'
import ProgressBar from '../../components/ProgressBar'

registerLocale('es', es)
setDefaultLocale('es')

const CreateSupplier = props => {
  const [productSelected, setProductSelected] = useState(null)
  const [amount, setAmount] = useState(0)
  const [packing, setPacking] = useState(0)
  const [packingString, setPackingString] = useState(0)
  const [shippingProducts, setShippingProducts] = useState([])
  const [shippingNumber, setShippingNumber] = useState(0)
  const [observations, setObservations] = useState('')
  const [customer, setCustomer] = useState(null)
  const [price, setPrice] = useState(0)
  const [start, setStart] = useState(new Date())
  const [newCapacity, setNewCapacity] = useState([])
  const {
    setTitle,
    products,
    stock,
    locations,
    warehouses,
    customers,
    capacity,
  } = props

  useEffect(() => {
    const topbar = {
      title: 'Crear Pedido',
    }
    setTitle(topbar)

    props
      .getAll('products', 'GET_PRODUCTS')
      .then(() => {
        props.getAll('customers', 'GET_CUSTOMERS')
      })
      .then(() => {
        props.getAll('products/models', 'GET_MODELS')
      })
      .then(() => {
        props.getAll('products/colors', 'GET_COLORS')
      })
      .then(() => {
        props.getAll('stock', 'GET_STOCK')
      })
      .then(() => {
        props.getAll('warehouses', 'GET_WAREHOUSES')
      })
      .then(() => {
        props.getAll('locations', 'GET_LOCATIONS')
      })
    // eslint-disable-next-line
  }, [])

  useEffect(() => {
    if (capacity) {
      setNewCapacity(capacity)
    }
  }, [capacity])

  const handleSubmit = () => {
    if (
      shippingProducts.length > 0 &&
      shippingNumber !== 0 &&
      customer !== null &&
      newCapacity[0].capacity > 100
    ) {
      Swal.fire({
        title: '¿El pedido supera la capacidad de producción?',
        text: 'Este proceso no se puede revertir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, guardar',
      }).then(result => {
        if (result.isConfirmed) {
          props
            .create('orders', 'POST_ORDERS', {
              number: shippingNumber,
              customer_id: customer,
              products: shippingProducts,
              observations,
              delivery: moment(start).format('YYYY-MM-DD'),
            })
            .then(() => {
              window.location.href = '/dashboard/oc'
            })
        }
      })
    } else if (
      shippingProducts.length > 0 &&
      shippingNumber !== 0 &&
      customer !== null &&
      newCapacity.length > 0
    ) {
      Swal.fire({
        title: '¿Estás seguro?',
        text: 'Este proceso no se puede revertir',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonColor: '#3085d6',
        cancelButtonColor: '#d33',
        confirmButtonText: 'Si, guardar',
      }).then(result => {
        if (result.isConfirmed) {
          props
            .create('orders', 'POST_ORDERS', {
              number: shippingNumber,
              customer_id: customer,
              products: shippingProducts,
              observations,
              delivery: moment(start).format('YYYY-MM-DD'),
            })
            .then(() => {
              window.location.href = '/dashboard/oc'
            })
        }
      })
    } else {
      Swal.fire(
        'Cuidado!',
        'Agrege un número de pedido, seleccione un producto, una fecha de entrega y un cliente.',
        'info'
      )
    }
  }

  const handleAdd = () => {
    const product = products.find(
      product => product.id === productSelected
    )

    if (
      shippingProducts.find(
        sp => sp.product_id === productSelected
      ) === undefined
    ) {
      const capacityOrder =
        (parseInt(amount) / parseInt(product.model.capacity)) * 100
      setNewCapacity(
        newCapacity.map((item, index) => {
          if (index === 0) {
            return {
              ...item,
              capacity: item.capacity + capacityOrder,
            }
          } else {
            return item
          }
        })
      )

      setShippingProducts([
        ...shippingProducts,
        {
          product_id: productSelected,
          amount,
          packing,
          packingString,
          price,
        },
      ])
    } else {
      Swal.fire(
        'Cuidado!',
        'Este producto ya se encuentra en la lista.',
        'info'
      )
    }
  }

  const handleDelete = id => {
    const product = products.find(product => product.id === id)

    const capacityOrder =
      (parseInt(amount) / parseInt(product.model.capacity)) * 100
    setNewCapacity(
      newCapacity.map((item, index) => {
        if (index === 0) {
          return {
            ...item,
            capacity: item.capacity - capacityOrder,
          }
        }
        return item
      })
    )

    setShippingProducts(
      shippingProducts.filter(sp => sp.product_id !== id)
    )
  }

  const onChange = date => {
    setStart(date)
    props.getAll(
      `production/capacity/${moment(date).format('YYYY-MM-DD')}`,
      'GET_CAPACITY'
    )
  }

  if (
    !products ||
    !stock ||
    !locations ||
    !warehouses ||
    !customers
  ) {
    return <h1>Cargando...</h1>
  }

  return (
    <>
      <Card title={'Número de pedido'}>
        <Select
          options={customers.map(customer => ({
            value: customer.CCODIGOCLIENTE,
            label: `${customer.CCODIGOCLIENTE} - ${customer.CRAZONSOCIAL}`,
          }))}
          onChange={e => setCustomer(e.value)}
          placeholder="Seleccione un cliente"
        />
        <Input
          type="text"
          name="number"
          title="Referencia"
          onChange={e => setShippingNumber(e.target.value)}
          required
        />
        <Input
          type="text"
          name="observations"
          title="Observaciones"
          onChange={e => setObservations(e.target.value)}
          required
        />
        <label htmlFor="">Fecha de entrega</label>
        <DatePicker selected={start} onChange={onChange} inline />
      </Card>
      {newCapacity.length > 0 && (
        <Card title={'Capacidad'}>
          <p>Capacidad de producción: </p>
          {newCapacity.map((item, index) => (
            <div>
              <p key={index}>
                Del {item.weekStart} al {item.weekEnd}
              </p>
              <ProgressBar data={item.capacity} />
            </div>
          ))}
        </Card>
      )}

      <Card title={'Productos'}>
        <Select
          options={products.map(product => ({
            value: product.id,
            label: `${product.model.model} ${product.color} ${
              product.barcode !== null && product.barcode !== ''
                ? ` - ${product.barcode}`
                : ''
            }${
              product.alias !== null && product.alias !== ''
                ? ` - ${product.alias}`
                : ''
            }`,
          }))}
          onChange={e => setProductSelected(e.value)}
          placeholder="Seleccione un producto"
        />

        <Select
          options={[
            {
              value: 0,
              label: 'Sin empaque',
            },
            {
              value: 'EMP',
              label: 'EMP',
            },
            {
              value: 'EMPAQ',
              label: 'EMPAQ',
            },
          ]}
          onChange={e => setPackingString(e.value)}
          placeholder="Seleccione un tipo de empaque"
        />
        {packingString !== 0 && (
          <Input
            type="text"
            name="packing"
            title="Empaque"
            onChange={e => setPacking(e.target.value)}
          />
        )}
        <Input
          type="text"
          name="number"
          title="Cantidad"
          onChange={e => setAmount(e.target.value)}
          required
        />
        <Input
          type="text"
          name="price"
          title="Precio"
          onChange={e => setPrice(e.target.value)}
          required
        />
        <Button type="button" onClick={handleAdd}>
          Agregar
        </Button>
      </Card>
      <Card>
        <Table>
          <Thead>
            <Tr>
              <Th>Modelo</Th>
              <Th>Alias</Th>
              <Th>Color</Th>
              <Th>Empaque</Th>
              <Th>Cantidad</Th>
              <Th>Descripción</Th>
              <Th>Precio</Th>
              <Th>Acciones</Th>
            </Tr>
          </Thead>
          <Tbody>
            {shippingProducts.length > 0 &&
              shippingProducts.map((product, index) => {
                const p = products.find(
                  pr => pr.id === product.product_id
                )
                return (
                  <Tr key={index}>
                    <Td>{p?.model.model}</Td>
                    <Td>{p?.alias}</Td>
                    <Td>{p?.color}</Td>
                    <Td>{product.packing}</Td>
                    <Td>{product.amount}</Td>
                    <Td>
                      {p?.description}{' '}
                      {product.packingString !== 0
                        ? `${product.packingString}:${product.packing}`
                        : ``}
                    </Td>
                    <Td>{product.price}</Td>
                    <Td>
                      <Button
                        type="button"
                        onClick={() =>
                          handleDelete(product.product_id)
                        }
                      >
                        Borrar
                      </Button>
                    </Td>
                  </Tr>
                )
              })}
          </Tbody>
        </Table>
      </Card>
      <BarButton>
        <Button type="button" color="success" onClick={handleSubmit}>
          Crear
        </Button>
        <Link to="/orders">
          <Button className="btn --danger">Cancelar</Button>
        </Link>
      </BarButton>
    </>
  )
}

const mapStateToProps = state => {
  return {
    customers: state.reducerApp.customers,
    products: state.products.products,
    stock: state.reducerApp.stock,
    colors: state.reducerApp.colors,
    locations: state.reducerApp.locations,
    warehouses: state.reducerApp.warehouses,
    localization: state.reducerApp.localization,
    capacity: state.reducerApp.capacity,
  }
}

const mapDispatchToProps = {
  create,
  getAll,
  setTitle,
}

export default connect(
  mapStateToProps,
  mapDispatchToProps
)(CreateSupplier)

const BarButton = styled.div`
  margin-top: 1rem;
`
const Table = styled.table`
  border-collapse: collapse;
  width: 100%;
  border-spacing: 0;
  box-sizing: border-box;
`

const Thead = styled.thead`
  border: ${({ theme }) => theme.border};
`

const Tbody = styled.thead`
  border: ${({ theme }) => theme.border};
  > tr {
    ${props => (props.selected ? 'cursor: pointer;' : '')};
    :hover {
      background-color: ${props =>
        props.selected ? props.theme.menu_category : ''};
    }
  }
`

const Tr = styled.tr`
  border: ${({ theme }) => theme.border};
`
const Th = styled.th`
  padding: 0.75rem;
  text-align: left;
  font-size: 0.8rem;
  @media (max-width: 768px) {
    padding: 0.5rem;
    font-size: 0.7rem;
  }
`
const Td = styled.td`
  padding: 0.75rem;
  font-size: 0.8rem;
  background-color: ${props =>
    parseInt(props.delivered) === 1 ? props.theme.success : ''};
  @media (max-width: 768px) {
    padding: 0.5rem;
    font-size: 0.7rem;
  }
`
